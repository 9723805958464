import React, { useState } from "react"
import { withRouter, Link } from "react-router-dom"
import Cookies from "js-cookie"

import {
	Navbar,
	Nav,
	NavDropdown,
	DropdownButton,
	Dropdown,
	Modal
} from "react-bootstrap"

import uuid from "react-uuid"
import QueryCreate from "../../../page/dashboard/query/create"
import wrapper from "../../../../i18n/wrapper"

const logout = ({ history }) => {
	Cookies.remove("authentication")
	history.push("/uyelik/giris")
}

const QueryComponent = wrapper(({ t }) => {
	const [show, setShow] = useState(false)

	const query = uuid()
	const protocol = window.location.protocol
	const domain = window.location.hostname
	const location = protocol + "//" + domain + "/#!/dogrulama/" + query

	return (
		<>
			<NavDropdown.Item onClick={() => setShow(true)}>
				{t("link.create.query")}
			</NavDropdown.Item>
			<Modal show={show} onHide={() => setShow(false)} size="lg">
				<Modal.Header closeButton>
					<Modal.Title>{t("header.query")}</Modal.Title>
				</Modal.Header>
				<QueryCreate setShow={setShow} location={location} query={query} />
			</Modal>
		</>
	)
})

export default withRouter(
	wrapper(({ history, module, t }) => (
		<Navbar id="header" fixed="top" bg="dark" variant="dark">
			<Navbar.Brand>PANEL</Navbar.Brand>
			<Navbar.Collapse id="basic-navbar-nav">
				<Nav className="mr-auto">
					<Link
						to="/panel/onaylar"
						className={module === "storage" ? "nav-link active" : "nav-link"}
					>
						{t("link.storage")}
					</Link>
					<Link
						to="/panel/dogrulamalar"
						className={module === "history" ? "nav-link active" : "nav-link"}
					>
						{t("link.history")}
					</Link>
					<Link
						to="/panel/musteriler"
						className={module === "contact" ? "nav-link active" : "nav-link"}
					>
						{t("link.contact")}
					</Link>
				</Nav>
				<Nav>
					<NavDropdown title={t("link.profile")} drop="left">
						<Dropdown.Header>
							<b>{t("header.profile")}</b>
						</Dropdown.Header>
						<Dropdown.Divider />
						<Link to="/panel/sirket/duzenle" className="dropdown-item">
							{t("link.edit.team")}
						</Link>
						<NavDropdown.Item onClick={() => logout({ history })}>
							{t("link.logout")}
						</NavDropdown.Item>
					</NavDropdown>
					<DropdownButton title={t("button.create")} drop="left">
						<Dropdown.Header>
							<b>{t("header.create")}</b>
						</Dropdown.Header>
						<Dropdown.Divider />
						<QueryComponent />
						<Link to="/panel/onaylar/olustur" className="dropdown-item">
							{t("link.create.storage")}
						</Link>
						<Link to="/panel/musteriler/olustur" className="dropdown-item">
							{t("link.create.contact")}
						</Link>
					</DropdownButton>
				</Nav>
			</Navbar.Collapse>
		</Navbar>
	))
)
