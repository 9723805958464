import React from "react"
import ReactDOM from "react-dom"
import { createStore } from "redux"
import { Provider } from "react-redux"
import { ApolloProvider } from "react-apollo"
import { Rehydrated } from "aws-appsync-react"

import AWS from "aws-sdk"
import Client from "aws-appsync"

import "./i18n/config"
import { Spinner } from "react-bootstrap"
import root from "./store/root"
import App from "./views/app"

AWS.config.region = "eu-central-1"
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
	IdentityPoolId: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID
})

const store = createStore(root)
const client = new Client(
	{
		disableOffline: true,
		url: process.env.REACT_APP_AWS_GRAPHQL_ENDPOINT,
		region: process.env.REACT_APP_AWS_GRAPHQL_REGION,
		auth: {
			type: process.env.REACT_APP_AWS_GRAPHQL_AUTH_TYPE,
			credentials: AWS.config.credentials
		}
	},
	{
		defaultOptions: {
			watchQuery: { fetchPolicy: "no-cache" },
			query: { fetchPolicy: "no-cache" }
		}
	}
)

ReactDOM.render(
	<Provider store={store}>
		<ApolloProvider client={client}>
			<Rehydrated
				render={({ rehydrated }) =>
					rehydrated ? (
						<App />
					) : (
						<div id="center">
							<Spinner animation="border" variant="secondary" size="sm" />
						</div>
					)
				}
			/>
		</ApolloProvider>
	</Provider>,
	document.getElementById("root")
)
