import React, { Component } from "react"
import { Link } from "react-router-dom"
import { get, isObject } from "lodash"
import moment from "moment"
import hideText from "hide-text"

import { Container, Image, Table } from "react-bootstrap"
import wrapper from "../../../i18n/wrapper"

export default (Original) => {
	return wrapper(
		class Created extends Component {
			render() {
				const { content, success, legal, t } = this.props

				const image = get(content, "team.image")
				const title = get(content, "team.title")
				const website = get(content, "team.website")
				const clarification = get(content, "team.clarification")
				const owner = get(content, "contact.title")

				const privacy = get(content, "privacy")
				const category = get(content, "category")
				const created = get(content, "created_at")
				const sourceLanguage = get(content, "sourceLanguage")
				const targetLanguage = get(content, "targetLanguage")

				return (
					<div id="center">
						<Container className="compact">
							<div className="text-center">
								{image ? (
									<Image
										src={image}
										alt="logo"
										style={{ maxHeight: "100px", maxWidth: "300px" }}
									/>
								) : (
									<i className="fas fa-globe-europe fa-5x text-secondary"></i>
								)}
								{website ? (
									<Link to={website} target="_blank">
										<h5 className="text-secondary mt-2">{title}</h5>
									</Link>
								) : (
									<h5 className="text-secondary mt-2">{title}</h5>
								)}
							</div>
							<hr className="my-3" />
							<Original {...this.props} />
						</Container>
						<Container className="compact">
							<Table responsive>
								<tbody>
									<tr>
										<td>
											<b>{t("input.owner")}</b>
										</td>
										<td>
											{success ? owner : hideText(owner, { showLeft: 3 })}
										</td>
									</tr>
									<tr>
										<td>
											<b>{t("input.created.storage")}</b>
										</td>
										<td>{moment.unix(created).format("D MMMM yyyy")}</td>
									</tr>
									<tr>
										<td>
											<b>{t("input.privacy")}</b>
										</td>
										<td>{t("select.privacy." + privacy)}</td>
									</tr>
									<tr>
										<td>
											<b>{t("input.pair")}</b>
										</td>
										<td>
											{t("select.language." + sourceLanguage)}
											<i className="fas fa-chevron-right mx-1"></i>
											{t("select.language." + targetLanguage)}
										</td>
									</tr>
									<tr>
										<td>
											<b>{t("input.category.storage")}</b>
										</td>
										<td>
											{success ? category : hideText(category, { showLeft: 3 })}
										</td>
									</tr>
								</tbody>
							</Table>
						</Container>
						<Container className={!success ? "d-none" : ""}>
							<hr className="my-3" />
							<div className="text-center">
								<h5>{t("header.legal")}</h5>
								{isObject(legal) ? (
									<>
										<p
											className="text-secondary mb-0"
											dangerouslySetInnerHTML={{
												__html: t("content.legal.1", {
													href: clarification
														? clarification
														: "https://e-onayla.com/gdpr/"
												})
											}}
										/>
										<p
											className="text-secondary mb-0"
											dangerouslySetInnerHTML={{
												__html: legal.status
													? t("content.legal.2")
													: t("content.legal.3")
											}}
										/>
										<p
											className="text-secondary mb-0"
											dangerouslySetInnerHTML={{ __html: t("content.legal.4") }}
										/>
										<p
											className="text-secondary mb-0"
											dangerouslySetInnerHTML={{ __html: t("content.legal.5") }}
										/>
									</>
								) : (
									<p
										className="text-secondary"
										dangerouslySetInnerHTML={{ __html: t("content.legal.0") }}
									/>
								)}
							</div>
						</Container>
					</div>
				)
			}
		}
	)
}
