import React from "react"
import ReCAPTCHA from "react-google-recaptcha"

export default ({ reference }) => (
	<ReCAPTCHA
		ref={reference}
		sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA}
		badge="bottomright"
		size="invisible"
	/>
)
