import React from "react"
import { Modal, Form, Col, InputGroup, Button, Spinner } from "react-bootstrap"
import wrapper from "../../../../../i18n/wrapper"

export default wrapper(({ handleChange, values, loading, t }) => {
	const identity = Number(values.identity)

	return (
		<>
			<Modal.Body>
				<Form.Group>
					<Form.Label>
						<b className="text-secondary">{t("input.title.contact")}</b>
					</Form.Label>
					<Form.Control
						name="title"
						type="text"
						autoComplete="off"
						placeholder={t("placeholder.contact")}
						value={values.title}
						onChange={handleChange}
					/>
				</Form.Group>
				<Form.Group>
					<Form.Label>
						<b className="text-secondary">{t("input.identity.contact")}</b>
					</Form.Label>
					<Form.Row>
						<Col>
							<Form.Check
								id="identity-individual"
								type="radio"
								label={t("select.identity.1")}
								name="identity"
								value={1}
								checked={identity === 1}
								onChange={handleChange}
							/>
						</Col>
						<Col>
							<Form.Check
								id="identity-corporate"
								type="radio"
								label={t("select.identity.2")}
								name="identity"
								value={2}
								checked={identity === 2}
								onChange={handleChange}
							/>
						</Col>
					</Form.Row>
				</Form.Group>
				<Form.Group>
					<Form.Label>
						<b className="text-secondary">{t("input.number.contact")}</b>
					</Form.Label>
					<Form.Control
						name="number"
						type="number"
						autoComplete="off"
						placeholder={t("placeholder.number.contact")}
						value={values.number}
						onChange={handleChange}
					/>
				</Form.Group>
				<Form.Group className={identity === 1 ? "d-none" : ""}>
					<Form.Label>
						<b className="text-secondary">{t("input.office")}</b>
					</Form.Label>
					<Form.Control
						name="office"
						type="text"
						autoComplete="off"
						placeholder={t("placeholder.office.contact")}
						value={values.office}
						onChange={handleChange}
					/>
				</Form.Group>
				<Form.Row>
					<Col className={identity === 1 ? "d-none" : ""}>
						<Form.Group>
							<Form.Label>
								<b className="text-secondary">{t("input.title.auth")}</b>
							</Form.Label>
							<InputGroup>
								<InputGroup.Prepend>
									<InputGroup.Text>
										<i className="fas fa-user"></i>
									</InputGroup.Text>
								</InputGroup.Prepend>
								<Form.Control
									name="auth"
									type="text"
									autoComplete="off"
									value={values.auth}
									onChange={handleChange}
								/>
							</InputGroup>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group>
							<Form.Label>
								<b className="text-secondary">{t("input.phone")}</b>
							</Form.Label>
							<InputGroup>
								<InputGroup.Prepend>
									<InputGroup.Text>
										<i className="fas fa-phone"></i>
									</InputGroup.Text>
								</InputGroup.Prepend>
								<Form.Control
									name="phone"
									type="number"
									autoComplete="off"
									value={values.phone}
									onChange={handleChange}
								/>
							</InputGroup>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group>
							<Form.Label>
								<b className="text-secondary">{t("input.email")}</b>
							</Form.Label>
							<InputGroup>
								<InputGroup.Prepend>
									<InputGroup.Text>
										<i className="fas fa-envelope"></i>
									</InputGroup.Text>
								</InputGroup.Prepend>
								<Form.Control
									name="email"
									type="text"
									autoComplete="off"
									value={values.email}
									onChange={handleChange}
								/>
							</InputGroup>
						</Form.Group>
					</Col>
				</Form.Row>
			</Modal.Body>
			<Modal.Footer>
				<Form.Group className="text-right">
					<Button variant="primary" type="submit" disabled={loading}>
						{loading ? (
							<Spinner animation="border" size="sm" />
						) : (
							t("button.create")
						)}
					</Button>
				</Form.Group>
			</Modal.Footer>
		</>
	)
})
