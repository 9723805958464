import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import { Query } from "react-apollo"
import { get } from "lodash"
import gql from "graphql-tag"

const query = `
	query getBaseDomain($domain: String!) {
		get:getBaseDomain(domain: $domain) {
			PK
		}
	}
`

export default (Original) => {
	return withRouter(
		class Created extends Component {
			render() {
				const { match, history } = this.props
				const domain = window.location.hostname

				return (
					<Query query={gql(query)} variables={{ domain: domain }}>
						{(result) => {
							let team = get(result, "data.get.PK")
							team = team ? team.replace(/^baseTeam#/, "") : null

							return (
								<Original
									history={history}
									match={match}
									domain={domain}
									team={team}
								/>
							)
						}}
					</Query>
				)
			}
		}
	)
}
