import React, { useState } from "react"
import { Mutation } from "react-apollo"
import { get, size } from "lodash"
import gql from "graphql-tag"

import { Formik } from "formik"
import { Alert, Form, InputGroup, Button, Spinner } from "react-bootstrap"

import Wrapping from "../../../layout/wrapper/download"
import wrapper from "../../../../i18n/wrapper"
import Code from "./sms/code"

const query = `
	mutation createLogHistorySms($storage: String!, $input: createInputLogHistorySms!){
		create:createLogHistorySms(storage: $storage, input: $input){
			SK
		}
	}
`

const Component = Wrapping(
	({
		handleSubmit,
		handleChange,
		setError,
		setSuccess,
		setLegal,
		content,
		values,
		error,
		success,
		loading,
		storage,
		history,
		t
	}) => (
		<>
			<div className={success ? "my-2 text-center d-none" : "my-2 text-center"}>
				<h3>{t("header.download")}</h3>
				<p className="text-secondary">{t("content.download.sms")}</p>
			</div>
			{error ? <Alert variant="danger">{t(error)}</Alert> : null}
			<Form onSubmit={handleSubmit}>
				<Form.Group className={success ? "d-none" : ""}>
					<InputGroup>
						<Form.Control
							name="phone"
							type="number"
							placeholder={t("input.phone")}
							autoComplete="off"
							value={values.phone}
							onChange={handleChange}
						/>
						<InputGroup.Append>
							<Button variant="secondary" disabled={loading} type="submit">
								{loading ? (
									<Spinner animation="border" size="sm" />
								) : (
									t("button.code")
								)}
							</Button>
						</InputGroup.Append>
					</InputGroup>
				</Form.Group>
			</Form>
			{history ? (
				<Code
					setSuccess={setSuccess}
					setLegal={setLegal}
					content={content}
					success={success}
					storage={storage}
					history={history}
				/>
			) : null}
		</>
	)
)

export default wrapper(({ content, storage, t }) => {
	const [error, setError] = useState(null)
	const [success, setSuccess] = useState(null)
	const [loading, setLoading] = useState(false)
	const [history, setHistory] = useState(null)
	const [legal, setLegal] = useState(null)

	return (
		<Mutation mutation={gql(query)}>
			{(create) => (
				<Formik
					initialValues={{
						phone: "90"
					}}
					onSubmit={({ phone }) => {
						setError(null)
						setLoading(true)

						create({
							variables: {
								storage: storage,
								input: {
									phone: size(String(phone)) ? "+" + Number(phone) : null,
									message: t("api.sms.message")
								}
							}
						})
							.then((response) => {
								setLoading(false)
								const history = get(response, "data.create.SK")

								if (history) {
									setHistory(history.replace(/^logHistory#/, ""))
								}
							})
							.catch((response) => {
								setLoading(false)
								const message = get(response, "graphQLErrors.0.message")

								if (message) {
									setError(message)
								}
							})
					}}
				>
					{({ handleSubmit, handleChange, values }) => (
						<Component
							handleSubmit={handleSubmit}
							handleChange={handleChange}
							setError={setError}
							setSuccess={setSuccess}
							setLegal={setLegal}
							content={content}
							values={values}
							error={error}
							success={success}
							loading={loading}
							storage={storage}
							history={history}
							legal={legal}
						/>
					)}
				</Formik>
			)}
		</Mutation>
	)
})
