import React, { useState } from "react"
import { withApollo } from "react-apollo"
import { AsyncTypeahead } from "react-bootstrap-typeahead"
import { get, size } from "lodash"
import gql from "graphql-tag"

const search = async ({
	client,
	query,
	setLoading,
	setOptions,
	authentication,
	string
}) => {
	setLoading(true)

	const result = await client.query({
		query: gql(query),
		variables: {
			authentication: authentication,
			filter: { title: { contains: string } }
		}
	})

	const items = get(result, "data.list.items")
	const options = items.map((index) => ({
		value: index.SK.replace(/^moduleContact#/, ""),
		title: index.title
	}))

	setOptions(options)
	setLoading(false)
}

const change = ({ setFieldValue, name, select }) => {
	const index = get(select, "0")
	setFieldValue(name, index)
}

export default withApollo(
	({
		client,
		query,
		name,
		placeholder,
		value,
		authentication,
		setFieldValue
	}) => {
		const [loading, setLoading] = useState(false)
		const [options, setOptions] = useState([])

		const title = get(value, "title")
		const option = size(value) ? [{ ...value }] : options
		const select = size(value) ? [title] : []

		return (
			<AsyncTypeahead
				id={"search-" + name}
				placeholder={placeholder}
				selected={select}
				labelKey="title"
				isLoading={loading}
				minLength={3}
				options={option}
				onChange={(select) => change({ setFieldValue, name, select })}
				onSearch={(string) =>
					search({
						client,
						query,
						setLoading,
						setOptions,
						authentication,
						string
					})
				}
				renderMenuItemChildren={(index) => index.title}
			/>
		)
	}
)
