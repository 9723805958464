import React, { useState } from "react"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import { get } from "lodash"

import {
	Form,
	Col,
	InputGroup,
	ButtonGroup,
	Modal,
	Button,
	Spinner
} from "react-bootstrap"
import uuid from "react-uuid"

import QueryCreate from "../../query/create"
import wrapper from "../../../../../i18n/wrapper"
import Upload from "../../../../layout/component/input/upload"

const QueryComponent = wrapper(({ setFieldValue, values, name, t }) => {
	const [show, setShow] = useState(false)

	const query = values[name]
	const protocol = window.location.protocol
	const domain = window.location.hostname
	const location = protocol + "//" + domain + "/#!/dogrulama/" + query

	return (
		<>
			<b className="text-primary" role="button" onClick={() => setShow(true)}>
				<i className="fas fa-plus mr-1"></i>
				{t("link.new")}
			</b>
			<Modal
				show={show}
				onShow={() => setFieldValue(name, uuid())}
				onHide={() => setShow(false)}
				size="lg"
			>
				<Modal.Header closeButton>
					<Modal.Title>{t("header.query")}</Modal.Title>
				</Modal.Header>
				<QueryCreate setShow={setShow} location={location} query={query} />
			</Modal>
		</>
	)
})

export default connect((state) => ({
	state: state
}))(
	wrapper(({ state, handleChange, setFieldValue, values, loading, t }) => {
		const language = get(state, "selection.language", [])
		const verification = Number(values.verification)

		const source = get(values, "source.s3.file", t("placeholder.source"))
		const target = get(values, "target.s3.file", t("placeholder.target"))

		return (
			<>
				<Modal.Body>
					<Form.Group>
						<Form.Label>
							<b className="text-secondary">{t("input.verification")}</b>
						</Form.Label>
						<Form.Row>
							<Col>
								<Form.Check
									id="verification-code"
									type="radio"
									label={t("select.verification.1")}
									name="verification"
									value={1}
									checked={verification === 1}
									onChange={handleChange}
								/>
							</Col>
							<Col>
								<Form.Check
									id="verification-sms"
									type="radio"
									label={t("select.verification.2")}
									name="verification"
									value={2}
									checked={verification === 2}
									onChange={handleChange}
								/>
							</Col>
							<Col>
								<Form.Check
									id="verification-identity"
									type="radio"
									label={t("select.verification.3")}
									name="verification"
									value={3}
									checked={verification === 3}
									onChange={handleChange}
								/>
							</Col>
						</Form.Row>
					</Form.Group>
					<Form.Group className={verification !== 1 ? "d-none" : ""}>
						<Form.Label>
							<b className="text-secondary">{t("input.verify")}</b>
						</Form.Label>
						<InputGroup>
							<InputGroup.Prepend>
								<InputGroup.Text>
									<i className="fas fa-key"></i>
								</InputGroup.Text>
							</InputGroup.Prepend>
							<Form.Control
								name="verify"
								type="text"
								autoComplete="off"
								placeholder={t("placeholder.verify")}
								value={values.verify}
								onChange={handleChange}
							/>
						</InputGroup>
					</Form.Group>
					<Form.Group>
						<Form.Label>
							<b className="text-secondary">{t("input.query")}</b>
						</Form.Label>
						<Form.Label className="float-right">
							<QueryComponent
								setFieldValue={setFieldValue}
								values={values}
								name="SK"
							/>
						</Form.Label>
						<InputGroup>
							<InputGroup.Prepend>
								<InputGroup.Text>
									<i className="fas fa-link"></i>
								</InputGroup.Text>
							</InputGroup.Prepend>
							<Form.Control
								name="SK"
								type="text"
								autoComplete="off"
								placeholder={t("placeholder.query")}
								value={values.SK}
								onChange={handleChange}
							/>
						</InputGroup>
					</Form.Group>
					<Form.Group>
						<Form.Label>
							<b className="text-secondary">{t("input.text")}</b>
						</Form.Label>
						<Form.Control
							name="text"
							as="textarea"
							rows="2"
							value={values.text}
							onChange={handleChange}
						/>
					</Form.Group>
					<Form.Row>
						<Col>
							<Form.Group>
								<Form.Label>
									<b className="text-secondary">{t("input.source")}</b>
								</Form.Label>
								<Upload
									label={source}
									name="source"
									timestamp={true}
									setFieldValue={setFieldValue}
								/>
							</Form.Group>
						</Col>
						<Col>
							<Form.Group>
								<Form.Label>
									<b className="text-secondary">{t("input.sourceLanguage")}</b>
								</Form.Label>
								<Form.Control
									as="select"
									name="sourceLanguage"
									value={values.sourceLanguage}
									onChange={handleChange}
									custom
								>
									{language.map((index, key) => (
										<option key={key} value={index.value}>
											{t(index.text)}
										</option>
									))}
								</Form.Control>
							</Form.Group>
						</Col>
					</Form.Row>
					<Form.Row>
						<Col>
							<Form.Group>
								<Form.Label>
									<b className="text-secondary">{t("input.target")}</b>
								</Form.Label>
								<Upload
									label={target}
									name="target"
									timestamp={true}
									setFieldValue={setFieldValue}
								/>
							</Form.Group>
						</Col>
						<Col>
							<Form.Group>
								<Form.Label>
									<b className="text-secondary">{t("input.targetLanguage")}</b>
								</Form.Label>
								<Form.Control
									as="select"
									name="targetLanguage"
									value={values.targetLanguage}
									onChange={handleChange}
									custom
								>
									{language.map((index, key) => (
										<option key={key} value={index.value}>
											{t(index.text)}
										</option>
									))}
								</Form.Control>
							</Form.Group>
						</Col>
					</Form.Row>
				</Modal.Body>
				<Modal.Footer>
					<Form.Group className="text-right">
						<ButtonGroup>
							<Link
								to="/panel/onaylar/olustur/temel"
								className="btn btn-secondary"
							>
								{t("button.previous")}
							</Link>
							<Button variant="primary" type="submit" disabled={loading}>
								{loading ? (
									<Spinner animation="border" size="sm" />
								) : (
									t("button.certify")
								)}
							</Button>
						</ButtonGroup>
					</Form.Group>
				</Modal.Footer>
			</>
		)
	})
)
