import React, { useState } from "react"
import axios from "axios"
import { get } from "lodash"

import { Form, Spinner } from "react-bootstrap"
import wrapper from "../../../../i18n/wrapper"

const region = "eu-central-1"
const bucket = "eonayla-documentation"
const extensions = [
	"bmp",
	"csv",
	"dita",
	"doc",
	"docm",
	"docx",
	"dot",
	"dotm",
	"dotx",
	"dtd",
	"fm",
	"g",
	"gif",
	"htm",
	"html",
	"icml",
	"idml",
	"inx",
	"itd",
	"jpeg",
	"jpg",
	"json",
	"md",
	"mif",
	"odp",
	"ods",
	"odt",
	"otp",
	"ots",
	"ott",
	"pdf",
	"png",
	"po",
	"pot",
	"potm",
	"potx",
	"pps",
	"ppsm",
	"ppsx",
	"ppt",
	"pptm",
	"pptx",
	"properties",
	"rar",
	"resx",
	"rtf",
	"sdlxliff",
	"sgm",
	"sgml",
	"srt",
	"strings",
	"sxml",
	"tag",
	"tiff",
	"tmx",
	"ts",
	"tsv",
	"ttx",
	"txml",
	"txt",
	"webp",
	"wix",
	"xhtml",
	"xlf",
	"xliff",
	"xls",
	"xlsm",
	"xlsx",
	"xlt",
	"xltm",
	"xltx",
	"xml",
	"xtg",
	"yaml",
	"yml",
	"zip",
	"eyp"
]

const initializer = (
	event,
	name,
	timestamp,
	file,
	type,
	setError,
	setLoading,
	setFieldValue
) => {
	const now = new Date()
	const time = Math.round(now.getTime())
	const token = Math.random().toString(36).substr(2)

	const result = get(event, "target.result")
	const folder = time + "/" + token
	const header = { type: type }

	axios({
		method: "POST",
		url: process.env.REACT_APP_AWS_ENDPOINT_UPLOAD,
		data: { region, bucket, file, folder }
	})
		.then((response) => {
			const array = []
			const signature = get(response, "data.signature")
			const binary = atob(result.split(",")[1])

			for (var i = 0; i < binary.length; i++) array.push(binary.charCodeAt(i))
			const data = new Blob([new Uint8Array(array)], header)

			fetch(signature, {
				method: "PUT",
				body: data,
				headers: { "Content-Disposition": "attachment" }
			}).then(
				(response) => {
					setFieldValue(name, {
						s3: { region, bucket, folder, file }
					})

					if (timestamp) {
						stamp({ name, folder, file, setError, setLoading, setFieldValue })
					} else {
						setLoading(false)
					}
				},
				(error) => {
					setLoading(false)
					setError(error)
				}
			)
		})
		.catch((error) => {
			setLoading(false)
			setError(true)
		})
}

const stamp = ({ name, folder, file, setError, setLoading, setFieldValue }) => {
	name = name + "Timestamp"

	axios({
		method: "POST",
		url: process.env.REACT_APP_AWS_ENDPOINT_TIMESTAMP,
		data: { region, bucket, folder, file }
	})
		.then((response) => {
			file = file + ".zd"

			setLoading(false)
			setFieldValue(name, {
				s3: { region, bucket, folder, file }
			})
		})
		.catch((error) => {
			setLoading(false)
			setError(error)
		})
}

const valite = ({ target }) => {
	const size = get(target, "size")
	const name = get(target, "name")
	const extension = name.match(/\.([0-9a-z]+)(?:[\?#]|$)/i)[1]

	if (size > 1024 * 1024 * 25) {
		return "error.browser.upload.size"
	} else if (extensions.indexOf(extension) < 0) {
		return "error.browser.upload.type"
	}

	return
}

const upload = (
	event,
	name,
	timestamp,
	setError,
	setLoading,
	setFieldValue
) => {
	const reader = new FileReader()
	const target = get(event, "target.files.0")
	const transfer = get(event, "dataTransfer.files.0")
	const validator = valite({ target })

	const input = target ? target : transfer
	const file = get(input, "name")
	const type = get(input, "type")

	if (validator) return setError(validator)

	setError(null)
	setLoading(true)

	reader.onload = (e) =>
		initializer(
			e,
			name,
			timestamp,
			file,
			type,
			setError,
			setLoading,
			setFieldValue
		)
	reader.readAsDataURL(input)
}

export default wrapper(
	({ label, name, disabled, timestamp, setFieldValue, timeout, t }) => {
		const [error, setError] = useState(null)
		const [loading, setLoading] = useState(false)

		return (
			<Form.File
				label={loading ? <Spinner animation="border" size="sm" /> : label}
				name={name}
				disabled={loading ? loading : disabled}
				isInvalid={error}
				onChange={(event) =>
					upload(event, name, timestamp, setError, setLoading, setFieldValue)
				}
				custom={true}
				feedback={t(error)}
			/>
		)
	}
)
