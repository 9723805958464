import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import Cookies from "js-cookie"
import Header from "../component/frame/header"

export default (Original) => {
	return withRouter(
		class Created extends Component {
			render() {
				const { match, module } = this.props
				const authentication = Cookies.get("authentication")

				return (
					<>
						<Header module={module} />
						<div id="fluid" className="py-2" style={{ marginTop: "3.5rem" }}>
							<Original match={match} authentication={authentication} />
						</div>
					</>
				)
			}
		}
	)
}
