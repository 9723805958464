import React, { useState } from "react"
import { Link } from "react-router-dom"
import { Mutation } from "react-apollo"
import { get } from "lodash"
import gql from "graphql-tag"

import { Formik } from "formik"
import { Container, Alert, Form, Col, Button, Spinner } from "react-bootstrap"

import wrapper from "../../../i18n/wrapper"
import Wrapping from "../../layout/wrapper/membership"

const query = `
	mutation createBasePasswordOuter($team: String!, $email: String!, $secret: String!, $input: createInputBasePassword!){
		create:createBasePasswordOuter(team: $team, email: $email, secret: $secret, input: $input){
			PK
			SK
		}
	}
`

const Component = wrapper(
	({ handleSubmit, handleChange, values, error, success, loading, t }) => {
		const domain = process.env.REACT_APP_STATIC_SUBDOMAIN
		const signup = "http://" + domain + "/#!/uyelik/kayit"

		return (
			<div id="center">
				<Container className="compact">
					<div className="my-2 text-center">
						<h3>{t("header.password")}</h3>
						<p className="text-secondary">{t("content.password")}</p>
					</div>
					{error ? (
						<Alert variant="danger">{t(error)}</Alert>
					) : success ? (
						<Alert variant="success">{t("success.graphql.password")}</Alert>
					) : null}
					<Form onSubmit={handleSubmit}>
						<Form.Group>
							<Form.Control
								name="password"
								type="password"
								placeholder={t("input.password")}
								autoComplete="off"
								value={values.password}
								onChange={handleChange}
							/>
						</Form.Group>
						<Form.Group>
							<Form.Control
								name="passwordConfirmation"
								type="password"
								placeholder={t("input.password_confirmation")}
								autoComplete="off"
								value={values.passwordConfirmation}
								onChange={handleChange}
							/>
						</Form.Group>
						<Form.Group>
							<Button variant="primary" disabled={loading} type="submit" block>
								{loading ? (
									<Spinner animation="border" size="sm" />
								) : (
									t("button.reset")
								)}
							</Button>
						</Form.Group>
						<Form.Row>
							<Col>
								<Form.Text>
									<a href={signup} className="text-muted">
										{t("link.signup")}
									</a>
								</Form.Text>
							</Col>
							<Col className="text-right">
								<Form.Text>
									<Link to="/uyelik/giris" className="text-muted">
										{t("link.login")}
									</Link>
								</Form.Text>
							</Col>
						</Form.Row>
					</Form>
				</Container>
			</div>
		)
	}
)

export default Wrapping(({ match, history, team }) => {
	const [error, setError] = useState(null)
	const [success, setSuccess] = useState(false)
	const [loading, setLoading] = useState(false)

	const email = get(match, "params.email")
	const secret = get(match, "params.secret")

	return (
		<Mutation mutation={gql(query)}>
			{(create) => (
				<Formik
					initialValues={{
						password: "",
						passwordConfirmation: ""
					}}
					onSubmit={({ password, passwordConfirmation }) => {
						setError(null)
						setLoading(true)

						create({
							variables: {
								team: team,
								email: email,
								secret: secret,
								input: { password, passwordConfirmation }
							}
						})
							.then((response) => {
								setLoading(false)
								setSuccess(true)
								setInterval(() => {
									history.push("/uyelik/giris")
								}, 1750)
							})
							.catch((response, error) => {
								setLoading(false)
								const type = get(response, "graphQLErrors.0.errorType")
								const message = get(response, "graphQLErrors.0.message")

								if (type && message) {
									setError(message)
								} else {
									setError("error.graphql.default")
								}
							})
					}}
				>
					{({ handleSubmit, handleChange, values }) => (
						<Component
							handleSubmit={handleSubmit}
							handleChange={handleChange}
							values={values}
							error={error}
							success={success}
							loading={loading}
						/>
					)}
				</Formik>
			)}
		</Mutation>
	)
})
