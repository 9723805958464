import React, { useState } from "react"
import { Link } from "react-router-dom"
import { Mutation } from "react-apollo"
import Cookies from "js-cookie"
import { get } from "lodash"
import gql from "graphql-tag"

import { Formik } from "formik"
import { Container, Col, Alert, Form, Button, Spinner } from "react-bootstrap"

import wrapper from "../../../i18n/wrapper"
import Wrapping from "../../layout/wrapper/membership"

const query = `
	mutation createBaseSession($team: String!, $input: createInputBaseSession!){
		create:createBaseSession(team: $team, input: $input){
			PK
			SK
		}
	}
`

const Component = wrapper(
	({ handleSubmit, handleChange, values, error, loading, t }) => {
		const domain = process.env.REACT_APP_STATIC_SUBDOMAIN
		const signup = "http://" + domain + "/#!/uyelik/kayit"

		return (
			<div id="center">
				<Container className="compact">
					<div className="my-2 text-center">
						<h3>{t("header.session")}</h3>
						<p className="text-secondary">{t("content.session")}</p>
					</div>
					{error ? <Alert variant="danger">{t(error)}</Alert> : null}
					<Form onSubmit={handleSubmit}>
						<Form.Group>
							<Form.Control
								name="email"
								type="text"
								placeholder={t("input.email")}
								autoComplete="off"
								value={values.email}
								onChange={handleChange}
							/>
						</Form.Group>
						<Form.Group>
							<Form.Control
								name="password"
								type="password"
								placeholder={t("input.password")}
								autoComplete="off"
								value={values.password}
								onChange={handleChange}
							/>
						</Form.Group>
						<Form.Group>
							<Button variant="primary" disabled={loading} type="submit" block>
								{loading ? (
									<Spinner animation="border" size="sm" />
								) : (
									t("button.login")
								)}
							</Button>
						</Form.Group>
						<Form.Row>
							<Col>
								<Form.Text>
									<a href={signup} className="text-muted">
										{t("link.signup")}
									</a>
								</Form.Text>
							</Col>
							<Col className="text-right">
								<Form.Text>
									<Link to="/uyelik/sifirlama" className="text-muted">
										{t("link.reset")}
									</Link>
								</Form.Text>
							</Col>
						</Form.Row>
					</Form>
				</Container>
			</div>
		)
	}
)

export default Wrapping(({ history, team }) => {
	const [error, setError] = useState(null)
	const [loading, setLoading] = useState(false)

	return (
		<Mutation mutation={gql(query)}>
			{(create) => (
				<Formik
					initialValues={{
						email: "",
						password: ""
					}}
					onSubmit={({ email, password }) => {
						setError(null)
						setLoading(true)

						create({
							variables: {
								team: team,
								input: {
									email: email.trim(),
									password
								}
							}
						})
							.then((response) => {
								setLoading(false)
								const create = get(response, "data.create")

								const pk = get(create, "PK")
								const sk = get(create, "SK")

								if (pk && sk) {
									const authentication = btoa(
										JSON.stringify({
											team: team,
											user: pk.replace(/^baseUser#/, ""),
											token: sk.replace(/^session#/, "")
										})
									)

									Cookies.set("authentication", authentication)
									history.push("/panel/onaylar")
								}
							})
							.catch((response, error) => {
								setLoading(false)
								const type = get(response, "graphQLErrors.0.errorType")
								const message = get(response, "graphQLErrors.0.message")

								if (type && message) {
									setError(message)
								} else {
									setError("error.graphql.default")
								}
							})
					}}
				>
					{({ handleSubmit, handleChange, values }) => (
						<Component
							handleSubmit={handleSubmit}
							handleChange={handleChange}
							values={values}
							error={error}
							loading={loading}
						/>
					)}
				</Formik>
			)}
		</Mutation>
	)
})
