import React, { useState } from "react"
import { Mutation } from "react-apollo"
import Cookies from "js-cookie"
import { get } from "lodash"
import gql from "graphql-tag"

import { Formik } from "formik"
import {
	Container,
	Alert,
	Form,
	InputGroup,
	Button,
	Spinner
} from "react-bootstrap"

import wrapper from "../../../i18n/wrapper"
import Wrapping from "../../layout/wrapper/membership"
import Captcha from "../../layout/component/input/captcha"

const captcha = React.createRef()
const query = `
	mutation createBaseDomain($domain: String!, $input: createInputBaseDomain!){
		create:createBaseDomain(domain: $domain, input: $input){
			PK
			SK
            GSI1P
		}
	}
`

const Component = wrapper(
	({ handleSubmit, handleChange, values, error, loading, t }) => (
		<div id="center">
			<Container className="compact">
				<div className="my-2 text-center">
					<h3>{t("header.domain")}</h3>
					<p className="text-secondary">{t("content.domain")}</p>
				</div>
				{error ? <Alert variant="danger">{t(error)}</Alert> : null}
				<Form onSubmit={handleSubmit}>
					<Form.Group>
						<InputGroup>
							<Form.Control
								name="subdomain"
								type="text"
								placeholder={t("input.subdomain")}
								autoComplete="off"
								value={values.subdomain}
								onChange={handleChange}
							/>
							<InputGroup.Append>
								<InputGroup.Text>
									{"." + process.env.REACT_APP_STATIC_DOMAIN}
								</InputGroup.Text>
							</InputGroup.Append>
						</InputGroup>
					</Form.Group>
					<Form.Group>
						<Form.Control
							name="name"
							type="text"
							placeholder={t("input.name")}
							autoComplete="off"
							value={values.name}
							onChange={handleChange}
						/>
					</Form.Group>
					<Form.Group>
						<Form.Control
							name="lastname"
							type="text"
							placeholder={t("input.lastname")}
							autoComplete="off"
							value={values.lastname}
							onChange={handleChange}
						/>
					</Form.Group>
					<Form.Group>
						<Form.Control
							name="email"
							type="text"
							placeholder={t("input.email")}
							autoComplete="off"
							value={values.email}
							onChange={handleChange}
						/>
					</Form.Group>
					<Form.Group>
						<Form.Control
							name="password"
							type="password"
							placeholder={t("input.password")}
							autoComplete="off"
							value={values.password}
							onChange={handleChange}
						/>
					</Form.Group>
					<Form.Group>
						<Captcha reference={captcha} />
					</Form.Group>
					<Form.Group>
						<p
							className="text-secondary"
							dangerouslySetInnerHTML={{
								__html: t("inline.signup", {
									href: "https://e-onayla.com/useragreement/"
								})
							}}
						/>
					</Form.Group>
					<Form.Group>
						<Button variant="primary" disabled={loading} type="submit" block>
							{loading ? (
								<Spinner animation="border" size="sm" />
							) : (
								t("button.signup")
							)}
						</Button>
					</Form.Group>
				</Form>
			</Container>
		</div>
	)
)

export default Wrapping(({ history }) => {
	const [error, setError] = useState(null)
	const [loading, setLoading] = useState(false)

	return (
		<Mutation mutation={gql(query)}>
			{(create) => (
				<Formik
					initialValues={{
						subdomain: "",
						name: "",
						lastname: "",
						email: "",
						password: ""
					}}
					onSubmit={async ({ subdomain, name, lastname, email, password }) => {
						setError(null)
						setLoading(true)

						const domain = subdomain + "." + process.env.REACT_APP_STATIC_DOMAIN

						create({
							variables: {
								domain: domain.trim(),
								input: {
									name,
									lastname,
									email: email.trim(),
									password,
									captcha: await captcha.current.executeAsync()
								}
							}
						})
							.then((response) => {
								setLoading(false)
								const create = get(response, "data.create")

								const pk = get(create, "PK")
								const sk = get(create, "SK")
								const gsi1p = get(create, "GSI1P")

								if (pk && sk && gsi1p) {
									const authentication = btoa(
										JSON.stringify({
											team: gsi1p.replace(/^baseTeam#/, ""),
											user: pk.replace(/^baseUser#/, ""),
											token: sk.replace(/^session#/, "")
										})
									)

									Cookies.set("authentication", authentication, { domain })
									window.location.href = "http://" + domain
								}
							})
							.catch((response, error) => {
								setLoading(false)
								const type = get(response, "graphQLErrors.0.errorType")
								const message = get(response, "graphQLErrors.0.message")

								if (type && message) {
									setError(message)
								} else {
									setError("error.graphql.default")
								}
							})
					}}
				>
					{({ handleSubmit, handleChange, values }) => (
						<Component
							handleSubmit={handleSubmit}
							handleChange={handleChange}
							values={values}
							error={error}
							loading={loading}
						/>
					)}
				</Formik>
			)}
		</Mutation>
	)
})
