import React from "react"
import { Badge } from "react-bootstrap"
import wrapper from "../../../../i18n/wrapper"

export default wrapper(({ t, index }) => {
	return index ? (
		<Badge variant="success">{t("select.status.1")}</Badge>
	) : (
		<Badge variant="warning">{t("select.status.0")}</Badge>
	)
})
