import React from "react"
import { Link } from "react-router-dom"
import { get } from "lodash"

export default ({ index, link }) => {
	const file = index ? get(JSON.parse(index), "s3.file") : null

	return index && link ? (
		<Link to={link} target="_blank">
			{file}
		</Link>
	) : (
		file
	)
}
