import React, { useState } from "react"
import { Query, Mutation } from "react-apollo"
import { get, size } from "lodash"
import gql from "graphql-tag"

import { Formik } from "formik"
import {
	Container,
	Alert,
	Spinner,
	Row,
	Col,
	Form,
	Modal,
	Button
} from "react-bootstrap"

import wrapper from "../../../../i18n/wrapper"
import Progress from "./edit/progress"
import Storage from "./edit/storage"
import Basic from "./edit/basic"

const queryGet = `
	query getBaseTeam($authentication: String!) {
		get:getBaseTeam(authentication: $authentication) {
            title
			website
			clarification
			identity
            companyTitle
            companyNumber
            companyOffice
            ownerTitle
            ownerEmail
            ownerPhone
          	taxation
          	obligation
          	operation
          	signature
          	agreement
          	status
        }
	}
`

const queryUpdate = `
    mutation updateBaseTeam($authentication: String!, $input: updateInputBaseTeam!){
        create:updateBaseTeam(authentication: $authentication, input: $input){
            created_at
            updated_at
        }
    }
`

const ComponentForm = wrapper(
	({
		handleSubmit,
		handleChange,
		setFieldValue,
		values,
		error,
		success,
		loading,
		t
	}) => (
		<Form onSubmit={handleSubmit} className="bg-light">
			{error ? (
				<Alert variant="danger">{t(error)}</Alert>
			) : success ? (
				<Alert variant="success">{t("success.graphql.team")}</Alert>
			) : null}
			<Basic handleChange={handleChange} values={values} />
			<Storage
				handleChange={handleChange}
				setFieldValue={setFieldValue}
				values={values}
			/>
			<Modal.Footer>
				<Form.Group className="text-right">
					<Button variant="primary" type="submit" disabled={loading}>
						{loading ? (
							<Spinner animation="border" size="sm" />
						) : (
							t("button.update")
						)}
					</Button>
				</Form.Group>
			</Modal.Footer>
		</Form>
	)
)

const ComponentBasic = wrapper(
	({
		handleSubmit,
		handleChange,
		setFieldValue,
		values,
		error,
		success,
		loading,
		t
	}) => (
		<Container>
			<div className="pb-5 text-center">
				<h2>{t("header.team")}</h2>
				<p className="lead">{t("content.team")}</p>
			</div>
			<Row>
				<Col md={8}>
					<ComponentForm
						handleSubmit={handleSubmit}
						handleChange={handleChange}
						setFieldValue={setFieldValue}
						values={values}
						error={error}
						success={success}
						loading={loading}
					/>
				</Col>
				<Col md={4} className="d-none d-md-block">
					<Progress values={values} />
				</Col>
			</Row>
		</Container>
	)
)

const ComponentDefault = wrapper(({ authentication, object }) => {
	const [error, setError] = useState(null)
	const [success, setSuccess] = useState(false)
	const [loading, setLoading] = useState(false)

	return (
		<Mutation mutation={gql(queryUpdate)}>
			{(create) => (
				<Formik
					initialValues={{
						title: object.title ? object.title : "",
						image: object.image ? object.image : "",
						website: object.website ? object.website : "",
						clarification: object.clarification ? object.clarification : "",
						identity: object.identity ? object.identity : 1,
						companyTitle: object.companyTitle ? object.companyTitle : "",
						companyNumber: object.companyNumber ? object.companyNumber : "",
						companyOffice: object.companyOffice ? object.companyOffice : "",
						ownerTitle: object.ownerTitle ? object.ownerTitle : "",
						ownerEmail: object.ownerEmail ? object.ownerEmail : "",
						ownerPhone: object.ownerPhone
							? object.ownerPhone.replace("+", "")
							: "90",
						taxation: object.taxation ? JSON.parse(object.taxation) : null,
						obligation: object.obligation
							? JSON.parse(object.obligation)
							: null,
						operation: object.operation ? JSON.parse(object.operation) : null,
						signature: object.signature ? JSON.parse(object.signature) : null,
						agreement: object.agreement ? JSON.parse(object.agreement) : null,
						status: object.status
					}}
					onSubmit={({
						title,
						image,
						website,
						clarification,
						identity,
						companyTitle,
						companyNumber,
						companyOffice,
						ownerTitle,
						ownerEmail,
						ownerPhone,
						taxation,
						obligation,
						operation,
						signature,
						agreement,
						status
					}) => {
						setError(null)
						setLoading(true)

						create({
							variables: {
								authentication: authentication,
								input: {
									title: size(String(title)) > 0 ? title : null,
									image: size(String(image)) > 0 ? image : null,
									website: size(String(website)) > 0 ? website : null,
									clarification:
										size(String(clarification)) > 0 ? clarification : null,
									identity: Number(identity),
									companyTitle:
										size(String(companyTitle)) > 0 ? companyTitle : null,
									companyNumber:
										size(String(companyNumber)) > 0
											? Number(companyNumber)
											: null,
									companyOffice:
										size(String(companyOffice)) > 0 ? companyOffice : null,
									ownerTitle: size(String(ownerTitle)) > 0 ? ownerTitle : null,
									ownerEmail:
										size(String(ownerEmail)) > 0 ? ownerEmail.trim() : null,
									ownerPhone: size(String(ownerPhone))
										? "+" + Number(ownerPhone)
										: null,
									taxation:
										taxation instanceof Object
											? JSON.stringify(taxation)
											: null,
									obligation:
										obligation instanceof Object
											? JSON.stringify(obligation)
											: null,
									operation:
										operation instanceof Object
											? JSON.stringify(operation)
											: null,
									signature:
										signature instanceof Object
											? JSON.stringify(signature)
											: null,
									agreement:
										agreement instanceof Object
											? JSON.stringify(agreement)
											: null
								}
							}
						})
							.then((response) => {
								setLoading(false)
								setSuccess(true)
							})
							.catch((response) => {
								setLoading(false)
								const type = get(response, "graphQLErrors.0.errorType")
								const message = get(response, "graphQLErrors.0.message")

								if (type && message) {
									setError(message)
								} else {
									setError("error.graphql.default")
								}
							})
					}}
				>
					{({ handleSubmit, handleChange, setFieldValue, values }) => (
						<ComponentBasic
							handleSubmit={handleSubmit}
							handleChange={handleChange}
							setFieldValue={setFieldValue}
							values={values}
							error={error}
							success={success}
							loading={loading}
						/>
					)}
				</Formik>
			)}
		</Mutation>
	)
})

export default wrapper(({ authentication, t }) => {
	return (
		<Query query={gql(queryGet)} variables={{ authentication: authentication }}>
			{({ loading, data }) => {
				const object = get(data, "get")

				return loading ? (
					<Container className="px-0 text-center" fluid>
						<Spinner animation="border" size="sm" />
					</Container>
				) : (
					<ComponentDefault authentication={authentication} object={object} />
				)
			}}
		</Query>
	)
})
