import React from "react"
import { get } from "lodash"
import { Modal, Form } from "react-bootstrap"

import wrapper from "../../../../../i18n/wrapper"
import Upload from "../../../../layout/component/input/upload"

export default wrapper(({ handleChange, setFieldValue, values, t }) => {
	const identity = Number(values.identity)
	const taxation = get(values, "taxation.s3.file", t("placeholder.taxation"))
	const operation = get(values, "operation.s3.file", t("placeholder.operation"))
	const signature = get(values, "signature.s3.file", t("placeholder.signature"))
	const agreement = get(values, "agreement.s3.file", t("placeholder.agreement"))
	const obligation = get(
		values,
		"obligation.s3.file",
		t("placeholder.obligation")
	)

	return (
		<>
			<Modal.Body>
				<Form.Group>
					<Form.Label>
						<b className="text-secondary">{t("input.taxation")}</b>
					</Form.Label>
					<Upload
						label={taxation}
						name="taxation"
						disabled={values.status}
						setFieldValue={setFieldValue}
					/>
					<Form.Text
						className="text-secondary"
						dangerouslySetInnerHTML={{
							__html: t("inline.documentation", {
								href: "https://ivd.gib.gov.tr/"
							})
						}}
					/>
				</Form.Group>
				<Form.Group>
					<Form.Label>
						<b className="text-secondary">{t("input.obligation")}</b>
					</Form.Label>
					<Upload
						label={obligation}
						name="obligation"
						disabled={values.status}
						setFieldValue={setFieldValue}
					/>
					<Form.Text
						className="text-secondary"
						dangerouslySetInnerHTML={{
							__html: t("inline.documentation", {
								href: "https://ivd.gib.gov.tr/"
							})
						}}
					/>
				</Form.Group>
				<Form.Group className={identity === 1 ? "d-none" : ""}>
					<Form.Label>
						<b className="text-secondary">{t("input.operation")}</b>
					</Form.Label>
					<Upload
						label={operation}
						name="operation"
						disabled={values.status}
						setFieldValue={setFieldValue}
					/>
				</Form.Group>
				<Form.Group className={identity === 1 ? "d-none" : ""}>
					<Form.Label>
						<b className="text-secondary">{t("input.signature")}</b>
					</Form.Label>
					<Upload
						label={signature}
						name="signature"
						disabled={values.status}
						setFieldValue={setFieldValue}
					/>
				</Form.Group>
				<Form.Group>
					<Form.Label>
						<b className="text-secondary">{t("input.agreement")}</b>
					</Form.Label>
					<Upload
						label={agreement}
						name="agreement"
						disabled={values.status}
						setFieldValue={setFieldValue}
					/>
					<Form.Text
						className="text-secondary"
						dangerouslySetInnerHTML={{
							__html: t("inline.useragreement", {
								href:
									"https://d1pvnul6t0k8be.cloudfront.net/document/useragreement.pdf"
							})
						}}
					/>
				</Form.Group>
			</Modal.Body>
		</>
	)
})
