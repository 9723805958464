import React, { useState } from "react"
import { withApollo } from "react-apollo"
import { get } from "lodash"
import gql from "graphql-tag"

import { ButtonGroup, Button, Spinner } from "react-bootstrap"
import wrapper from "../../../../i18n/wrapper"

const pagination = async ({
	client,
	setArray,
	setTokenize,
	setLoading,
	setObject,
	setPage,
	query,
	object,
	page,
	authentication,
	direction
}) => {
	const fetch = get(object, page)

	if (page === 0 || fetch) {
		setLoading(direction)

		const result = await client.query({
			query: gql(query),
			variables: { authentication: authentication, nextToken: fetch }
		})

		const items = get(result, "data.list.items")
		const token = get(result, "data.list.nextToken")
		const index = { [Number(page) + 1]: token }

		if (token) {
			setObject(Object.assign(object, index))
		}

		setPage(page)
		setArray(items)
		setTokenize(token)
		setLoading(null)
	}
}

const Component = wrapper(
	withApollo(
		({
			t,
			client,
			setArray,
			setTokenize,
			setLoading,
			setObject,
			setPage,
			query,
			loading,
			object,
			page,
			authentication,
			direction
		}) => (
			<Button
				variant="secondary"
				disabled={loading === direction}
				onClick={() =>
					pagination({
						client,
						setArray,
						setTokenize,
						setLoading,
						setObject,
						setPage,
						query,
						object,
						page,
						authentication,
						direction
					})
				}
			>
				{loading === direction ? (
					<Spinner animation="border" size="sm" />
				) : direction === "prev" ? (
					t("button.previous")
				) : (
					t("button.next")
				)}
			</Button>
		)
	)
)

export default ({ setArray, authentication, query, token }) => {
	const [tokenize, setTokenize] = useState(token)
	const [loading, setLoading] = useState(null)
	const [object, setObject] = useState({ 1: token })
	const [page, setPage] = useState(0)

	const prev = Number(page) - 1
	const next = Number(page) + 1

	return token ? (
		<ButtonGroup>
			{prev >= 0 ? (
				<Component
					setArray={setArray}
					setTokenize={setTokenize}
					setLoading={setLoading}
					setObject={setObject}
					setPage={setPage}
					query={query}
					loading={loading}
					object={object}
					page={prev}
					authentication={authentication}
					direction="prev"
				/>
			) : null}
			{tokenize ? (
				<Component
					setArray={setArray}
					setTokenize={setTokenize}
					setLoading={setLoading}
					setObject={setObject}
					setPage={setPage}
					query={query}
					loading={loading}
					object={object}
					page={next}
					authentication={authentication}
					direction="next"
				/>
			) : null}
		</ButtonGroup>
	) : null
}
