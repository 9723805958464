import React from "react"
import { get } from "lodash"

import Wrapping from "../../layout/wrapper/dashboard"
import Index from "./contact/index"
import Create from "./contact/create"

export default Wrapping(({ match, authentication }) => {
	const method = get(match, "params.method")

	return method === "olustur" ? (
		<Create authentication={authentication} />
	) : (
		<Index authentication={authentication} />
	)
})
