import React from "react"
import { Modal, Form, Col, InputGroup } from "react-bootstrap"
import wrapper from "../../../../../i18n/wrapper"

export default wrapper(({ handleChange, values, t }) => {
	const identity = Number(values.identity)

	return (
		<>
			<Modal.Body>
				<Form.Group>
					<Form.Label>
						<b className="text-secondary">{t("input.identity.team")}</b>
					</Form.Label>
					<Form.Row>
						<Col>
							<Form.Check
								id="identity-individual"
								type="radio"
								label={t("select.identity.1")}
								name="identity"
								value={1}
								checked={identity === 1}
								onChange={handleChange}
								disabled={values.status}
							/>
						</Col>
						<Col>
							<Form.Check
								id="identity-corporate"
								type="radio"
								label={t("select.identity.2")}
								name="identity"
								value={2}
								checked={identity === 2}
								onChange={handleChange}
								disabled={values.status}
							/>
						</Col>
					</Form.Row>
				</Form.Group>
				<Form.Group>
					<Form.Label>
						<b className="text-secondary">{t("input.title.team")}</b>
					</Form.Label>
					<Form.Control
						name="title"
						type="text"
						autoComplete="off"
						placeholder={t("placeholder.team")}
						value={values.title}
						onChange={handleChange}
					/>
				</Form.Group>
				<Form.Group>
					<Form.Label>
						<b className="text-secondary">{t("input.website")}</b>
					</Form.Label>
					<Form.Control
						name="website"
						type="text"
						autoComplete="off"
						placeholder={t("placeholder.website")}
						value={values.website}
						onChange={handleChange}
					/>
				</Form.Group>
				<Form.Group>
					<Form.Label>
						<b className="text-secondary">{t("input.clarification")}</b>
					</Form.Label>
					<Form.Control
						name="clarification"
						type="text"
						autoComplete="off"
						placeholder={t("placeholder.clarification")}
						value={values.clarification}
						onChange={handleChange}
					/>
				</Form.Group>
				<Form.Group className={identity === 1 ? "d-none" : ""}>
					<Form.Label>
						<b className="text-secondary">{t("input.title.company")}</b>
					</Form.Label>
					<Form.Control
						name="companyTitle"
						type="text"
						autoComplete="off"
						placeholder={t("placeholder.company")}
						value={values.companyTitle}
						onChange={handleChange}
						disabled
					/>
				</Form.Group>
				<Form.Group>
					<Form.Label>
						<b className="text-secondary">{t("input.number.team")}</b>
					</Form.Label>
					<Form.Control
						name="companyNumber"
						type="number"
						autoComplete="off"
						placeholder={t("placeholder.number.team")}
						value={values.companyNumber}
						onChange={handleChange}
						disabled
					/>
				</Form.Group>
				<Form.Group>
					<Form.Label>
						<b className="text-secondary">{t("input.office")}</b>
					</Form.Label>
					<Form.Control
						name="companyOffice"
						type="text"
						autoComplete="off"
						placeholder={t("placeholder.office.team")}
						value={values.companyOffice}
						onChange={handleChange}
						disabled
					/>
				</Form.Group>
				<Form.Row>
					<Col>
						<Form.Group>
							<Form.Label>
								<b className="text-secondary">{t("input.title.auth")}</b>
							</Form.Label>
							<InputGroup>
								<InputGroup.Prepend>
									<InputGroup.Text>
										<i className="fas fa-user"></i>
									</InputGroup.Text>
								</InputGroup.Prepend>
								<Form.Control
									name="ownerTitle"
									type="text"
									autoComplete="off"
									value={values.ownerTitle}
									onChange={handleChange}
								/>
							</InputGroup>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group>
							<Form.Label>
								<b className="text-secondary">{t("input.phone")}</b>
							</Form.Label>
							<InputGroup>
								<InputGroup.Prepend>
									<InputGroup.Text>
										<i className="fas fa-phone"></i>
									</InputGroup.Text>
								</InputGroup.Prepend>
								<Form.Control
									name="ownerPhone"
									type="number"
									autoComplete="off"
									value={values.ownerPhone}
									onChange={handleChange}
								/>
							</InputGroup>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group>
							<Form.Label>
								<b className="text-secondary">{t("input.email")}</b>
							</Form.Label>
							<InputGroup>
								<InputGroup.Prepend>
									<InputGroup.Text>
										<i className="fas fa-envelope"></i>
									</InputGroup.Text>
								</InputGroup.Prepend>
								<Form.Control
									name="ownerEmail"
									type="text"
									autoComplete="off"
									value={values.ownerEmail}
									onChange={handleChange}
								/>
							</InputGroup>
						</Form.Group>
					</Col>
				</Form.Row>
			</Modal.Body>
		</>
	)
})
