import React from "react"
import { HashRouter } from "react-router-dom"
import { Route, Switch, Redirect } from "react-router-dom"
import Cookies from "js-cookie"

import MembershipDomain from "./page/membership/domain"
import MembershipSession from "./page/membership/session"
import MembershipSecret from "./page/membership/secret"
import MembershipPassword from "./page/membership/password"
import DashboardTeam from "./page/dashboard/team"
import DashboardStorage from "./page/dashboard/storage"
import DashboardContact from "./page/dashboard/contact"
import DashboardHistory from "./page/dashboard/history"
import SourceDownload from "./page/source/download"
import SourceError from "./page/source/error"

const Home = () => {
	const authentication = Cookies.get("authentication")

	return authentication ? (
		<Redirect to={{ pathname: "/panel/onaylar" }} />
	) : (
		<Redirect to={{ pathname: "/uyelik/giris" }} />
	)
}

const Membership = ({ component: Component, path }) => {
	const authentication = Cookies.get("authentication")

	return (
		<Route
			path={path}
			render={(props) =>
				authentication ? (
					<Redirect to={{ pathname: "/panel/onaylar" }} />
				) : (
					<Component {...props} />
				)
			}
		/>
	)
}

const Dashboard = ({ component: Component, path }) => {
	const authentication = Cookies.get("authentication")

	return (
		<Route
			path={path}
			render={(props) =>
				authentication ? (
					<Component authentication={authentication} {...props} />
				) : (
					<Redirect to={{ pathname: "/uyelik/giris" }} />
				)
			}
		/>
	)
}

export default () => {
	const domain = window.location.hostname
	const subdomain = process.env.REACT_APP_STATIC_SUBDOMAIN

	return (
		<HashRouter hashType="hashbang">
			<Switch>
				<Membership
					path="/uyelik/kayit"
					component={() =>
						domain === subdomain ? (
							<MembershipDomain module="domain" />
						) : (
							<SourceError module="error" />
						)
					}
				/>
				<Membership
					path="/uyelik/giris"
					component={() =>
						domain === subdomain ? (
							<SourceError module="error" />
						) : (
							<MembershipSession module="session" />
						)
					}
				/>
				<Membership
					path="/uyelik/sifirlama"
					component={() =>
						domain === subdomain ? (
							<SourceError module="error" />
						) : (
							<MembershipSecret module="secret" />
						)
					}
				/>
				<Membership
					path="/uyelik/sifre/:email/:secret"
					component={() =>
						domain === subdomain ? (
							<SourceError module="error" />
						) : (
							<MembershipPassword module="password" />
						)
					}
				/>
				<Dashboard
					path="/panel/sirket/duzenle"
					component={() => <DashboardTeam module="team" />}
				/>
				<Dashboard
					path="/panel/musteriler/:method?"
					component={() => <DashboardContact module="contact" />}
				/>
				<Dashboard
					path="/panel/onaylar/:method?/:step?"
					component={() => <DashboardStorage module="storage" />}
				/>
				<Dashboard
					path="/panel/dogrulamalar"
					component={() => <DashboardHistory module="history" />}
				/>
				<Route
					path="/dogrulama/:storage"
					component={() => <SourceDownload module="download" />}
				/>
				<Home path="/" exact />
				<Route component={() => <SourceError module="error" />} />
			</Switch>
		</HashRouter>
	)
}
