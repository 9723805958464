import { UAParser } from "ua-parser-js"
import { get } from "lodash"

export default ({ index }) => {
	const parser = new UAParser()
	parser.setUA(index)

	const result = parser.getResult()
	const browser = get(result, "browser.name")
	const system = get(result, "os.name")

	return browser && system
		? system + " | " + browser
		: system
		? system
		: browser
}
