import React, { useState } from "react"
import { Query } from "react-apollo"
import { get, size } from "lodash"
import gql from "graphql-tag"

import { Container, Spinner } from "react-bootstrap"
import Table from "./index/table"

const query = `
	query listModuleStorage($authentication: String!, $nextToken: String) {
		list:listModuleStorage(authentication: $authentication, nextToken: $nextToken) {
			items {
				SK
				number
				invoice
				category
				privacy
				deadline
				verification
				verify
				source
				sourceLanguage
				target
				targetLanguage
				created_at
				updated_at
				contact {
					title
				}
			}
			nextToken
		}
	}
`

export default ({ authentication }) => {
	const [array, setArray] = useState([])

	return (
		<Query query={gql(query)} variables={{ authentication: authentication }}>
			{({ loading, data }) => {
				const token = get(data, "list.nextToken")
				const items = get(data, "list.items")
				const listing = size(array) > 0 ? array : items

				return loading ? (
					<Container className="px-0 text-center" fluid>
						<Spinner animation="border" size="sm" />
					</Container>
				) : (
					<Container className="px-0" fluid>
						<Table
							setArray={setArray}
							authentication={authentication}
							array={listing}
							token={token}
						/>
					</Container>
				)
			}}
		</Query>
	)
}
