import React, { useState } from "react"
import { Mutation } from "react-apollo"
import { get, size } from "lodash"
import gql from "graphql-tag"

import { Formik } from "formik"
import { Alert, Form, Button, Spinner } from "react-bootstrap"
import confirmation from "../../../../function/confirmation"
import Wrapping from "../../../layout/wrapper/download"

const query = `
	mutation createLogHistoryIdentity($storage: String!, $input: createInputLogHistoryIdentity!){
		create:createLogHistoryIdentity(storage: $storage, input: $input){
			source
			target
			targetTimestamp
		}
	}
`

const Component = Wrapping(
	({
		handleSubmit,
		handleChange,
		values,
		error,
		success,
		loading,
		timestamp,
		target,
		source,
		t
	}) => (
		<>
			<div className={success ? "my-2 text-center d-none" : "my-2 text-center"}>
				<h3>{t("header.download")}</h3>
				<p className="text-secondary">{t("content.download.identity")}</p>
			</div>
			{error ? <Alert variant="danger">{t(error)}</Alert> : null}
			<Form onSubmit={handleSubmit}>
				<Form.Group className={success ? "d-none" : ""}>
					<Form.Control
						name="number"
						type="number"
						placeholder={t("input.number.identity")}
						autoComplete="off"
						value={values.number}
						onChange={handleChange}
					/>
				</Form.Group>
				<Form.Group className={success ? "d-none" : ""}>
					<Form.Control
						name="name"
						type="text"
						placeholder={t("input.name")}
						autoComplete="off"
						value={values.name}
						onChange={handleChange}
					/>
				</Form.Group>
				<Form.Group className={success ? "d-none" : ""}>
					<Form.Control
						name="lastname"
						type="text"
						placeholder={t("input.lastname")}
						autoComplete="off"
						value={values.lastname}
						onChange={handleChange}
					/>
				</Form.Group>
				<Form.Group className={success ? "d-none" : ""}>
					<Form.Control
						name="birthyear"
						type="number"
						placeholder={t("input.birthyear")}
						autoComplete="off"
						value={values.birthyear}
						onChange={handleChange}
					/>
				</Form.Group>
				<Form.Group className={success ? "d-none" : ""}>
					<Button type="submit" variant="primary" disabled={loading} block>
						{loading ? (
							<Spinner animation="border" size="sm" />
						) : (
							t("button.verify")
						)}
					</Button>
				</Form.Group>
				<Form.Group className={!target ? "d-none" : ""}>
					<Button href={target} target="_blank" variant="primary" block>
						{t("button.target")}
					</Button>
				</Form.Group>
				<Form.Group className={!timestamp ? "d-none" : ""}>
					<Button href={timestamp} target="_blank" variant="secondary" block>
						{t("button.timestamp")}
					</Button>
				</Form.Group>
				<Form.Group className={!source ? "d-none" : ""}>
					<Button href={source} target="_blank" variant="secondary" block>
						{t("button.source")}
					</Button>
				</Form.Group>
			</Form>
		</>
	)
)

export default ({ content, storage }) => {
	const [error, setError] = useState(null)
	const [success, setSuccess] = useState(null)
	const [loading, setLoading] = useState(false)
	const [timestamp, setTimestamp] = useState(null)
	const [target, setTarget] = useState(null)
	const [source, setSource] = useState(null)
	const [legal, setLegal] = useState(null)

	return (
		<Mutation mutation={gql(query)}>
			{(create) => (
				<Formik
					initialValues={{
						country: "tr",
						number: "",
						name: "",
						lastname: "",
						birthyear: ""
					}}
					onSubmit={({ country, number, name, lastname, birthyear }) => {
						setError(null)
						setLoading(true)

						create({
							variables: {
								storage: storage,
								input: {
									country: country,
									number: size(String(number)) > 0 ? Number(number) : null,
									name: size(String(name)) > 0 ? name : null,
									lastname: size(String(lastname)) > 0 ? lastname : null,
									birthyear:
										size(String(birthyear)) > 0 ? Number(birthyear) : null
								}
							}
						})
							.then(async (response) => {
								setSuccess(true)
								setLoading(false)

								const source = get(response, "data.create.source")
								const target = get(response, "data.create.target")
								const timestamp = get(response, "data.create.targetTimestamp")

								setSource(source)
								setTarget(target)
								setTimestamp(timestamp)
								setLegal(await confirmation({ storage }))
							})
							.catch((response) => {
								setLoading(false)
								const type = get(response, "graphQLErrors.0.errorType")
								const message = get(response, "graphQLErrors.0.message")

								if (type && message) {
									setError(message)
								} else {
									setError("error.graphql.default")
								}
							})
					}}
				>
					{({ handleSubmit, handleChange, values }) => (
						<Component
							handleSubmit={handleSubmit}
							handleChange={handleChange}
							content={content}
							values={values}
							error={error}
							success={success}
							loading={loading}
							timestamp={timestamp}
							target={target}
							source={source}
							legal={legal}
						/>
					)}
				</Formik>
			)}
		</Mutation>
	)
}
