import { combineReducers } from "redux"

import selection from "./reducer/resource/selection"
import contact from "./reducer/module/contact"
import storage from "./reducer/module/storage"
import history from "./reducer/module/history"

export default combineReducers({
	selection,
	module: combineReducers({
		contact,
		storage,
		history
	})
})
