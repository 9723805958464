import { get } from "lodash"
import axios from "axios"

export default ({ storage }) => {
	return new Promise((resolve, reject) => {
		axios({
			method: "POST",
			url: process.env.REACT_APP_AWS_ENDPOINT_CONFIRMATION,
			data: { storage }
		}).then((response) => {
			const timestamp = get(response, "data.timestamp")
			resolve(timestamp)
		})
	})
}
