export const initial = {
	attribute: {
		loaded: false
	},
	content: []
}

export default (state = initial, action) => {
	return state
}
