import React from "react"
import { Link } from "react-router-dom"
import { Nav } from "react-bootstrap"
import wrapper from "../../../../../i18n/wrapper"

export default wrapper(({ step, t }) => (
	<Nav variant="pills" className="flex-column">
		<Nav.Item>
			<Link
				to="/panel/onaylar/olustur/temel"
				className={step !== "onayla" ? "nav-link active" : "nav-link"}
			>
				<h4>{t("step.storage.basic.header")}</h4>
				<p>{t("step.storage.basic.content")}</p>
			</Link>
		</Nav.Item>
		<Nav.Item>
			<Link
				to="/panel/onaylar/olustur/onayla"
				className={step === "onayla" ? "nav-link active" : "nav-link"}
			>
				<h4>{t("step.storage.verify.header")}</h4>
				<p>{t("step.storage.verify.content")}</p>
			</Link>
		</Nav.Item>
	</Nav>
))
