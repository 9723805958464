export default [
	{ value: "", text: "placeholder.language" },
	{ value: "tr", text: "select.language.tr" },
	{ value: "en", text: "select.language.en" },
	{ value: "de", text: "select.language.de" },
	{ value: "ar", text: "select.language.ar" },
	{ value: "fr", text: "select.language.fr" },
	{ value: "it", text: "select.language.it" },
	{ value: "es", text: "select.language.es" },
	{ value: "ja", text: "select.language.ja" },
	{ value: "ko", text: "select.language.ko" },
	{ value: "zh", text: "select.language.zh" },
	{ value: "ab", text: "select.language.ab" },
	{ value: "aa", text: "select.language.aa" },
	{ value: "af", text: "select.language.af" },
	{ value: "ak", text: "select.language.ak" },
	{ value: "sq", text: "select.language.sq" },
	{ value: "am", text: "select.language.am" },
	{ value: "an", text: "select.language.an" },
	{ value: "hy", text: "select.language.hy" },
	{ value: "as", text: "select.language.as" },
	{ value: "av", text: "select.language.av" },
	{ value: "ae", text: "select.language.ae" },
	{ value: "ay", text: "select.language.ay" },
	{ value: "az", text: "select.language.az" },
	{ value: "bm", text: "select.language.bm" },
	{ value: "ba", text: "select.language.ba" },
	{ value: "eu", text: "select.language.eu" },
	{ value: "be", text: "select.language.be" },
	{ value: "bn", text: "select.language.bn" },
	{ value: "bh", text: "select.language.bh" },
	{ value: "bi", text: "select.language.bi" },
	{ value: "bs", text: "select.language.bs" },
	{ value: "br", text: "select.language.br" },
	{ value: "bg", text: "select.language.bg" },
	{ value: "my", text: "select.language.my" },
	{ value: "ca", text: "select.language.ca" },
	{ value: "ch", text: "select.language.ch" },
	{ value: "ce", text: "select.language.ce" },
	{ value: "ny", text: "select.language.ny" },
	{ value: "cv", text: "select.language.cv" },
	{ value: "kw", text: "select.language.kw" },
	{ value: "co", text: "select.language.co" },
	{ value: "cr", text: "select.language.cr" },
	{ value: "hr", text: "select.language.hr" },
	{ value: "cs", text: "select.language.cs" },
	{ value: "da", text: "select.language.da" },
	{ value: "dv", text: "select.language.dv" },
	{ value: "nl", text: "select.language.nl" },
	{ value: "eo", text: "select.language.eo" },
	{ value: "et", text: "select.language.et" },
	{ value: "ee", text: "select.language.ee" },
	{ value: "fo", text: "select.language.fo" },
	{ value: "fj", text: "select.language.fj" },
	{ value: "fi", text: "select.language.fi" },
	{ value: "ff", text: "select.language.ff" },
	{ value: "gl", text: "select.language.gl" },
	{ value: "ka", text: "select.language.ka" },
	{ value: "el", text: "select.language.el" },
	{ value: "gn", text: "select.language.gn" },
	{ value: "gu", text: "select.language.gu" },
	{ value: "ht", text: "select.language.ht" },
	{ value: "ha", text: "select.language.ha" },
	{ value: "he", text: "select.language.he" },
	{ value: "hz", text: "select.language.hz" },
	{ value: "hi", text: "select.language.hi" },
	{ value: "ho", text: "select.language.ho" },
	{ value: "hu", text: "select.language.hu" },
	{ value: "ia", text: "select.language.ia" },
	{ value: "id", text: "select.language.id" },
	{ value: "ie", text: "select.language.ie" },
	{ value: "ga", text: "select.language.ga" },
	{ value: "ig", text: "select.language.ig" },
	{ value: "ik", text: "select.language.ik" },
	{ value: "io", text: "select.language.io" },
	{ value: "is", text: "select.language.is" },
	{ value: "iu", text: "select.language.iu" },
	{ value: "jv", text: "select.language.jv" },
	{ value: "kl", text: "select.language.kl" },
	{ value: "kn", text: "select.language.kn" },
	{ value: "kr", text: "select.language.kr" },
	{ value: "ks", text: "select.language.ks" },
	{ value: "kk", text: "select.language.kk" },
	{ value: "km", text: "select.language.km" },
	{ value: "ki", text: "select.language.ki" },
	{ value: "rw", text: "select.language.rw" },
	{ value: "ky", text: "select.language.ky" },
	{ value: "kv", text: "select.language.kv" },
	{ value: "kg", text: "select.language.kg" },
	{ value: "ku", text: "select.language.ku" },
	{ value: "kj", text: "select.language.kj" },
	{ value: "la", text: "select.language.la" },
	{ value: "lb", text: "select.language.lb" },
	{ value: "lg", text: "select.language.lg" },
	{ value: "li", text: "select.language.li" },
	{ value: "ln", text: "select.language.ln" },
	{ value: "lo", text: "select.language.lo" },
	{ value: "lt", text: "select.language.lt" },
	{ value: "lu", text: "select.language.lu" },
	{ value: "lv", text: "select.language.lv" },
	{ value: "gv", text: "select.language.gv" },
	{ value: "mk", text: "select.language.mk" },
	{ value: "mg", text: "select.language.mg" },
	{ value: "ms", text: "select.language.ms" },
	{ value: "ml", text: "select.language.ml" },
	{ value: "mt", text: "select.language.mt" },
	{ value: "mi", text: "select.language.mi" },
	{ value: "mr", text: "select.language.mr" },
	{ value: "mh", text: "select.language.mh" },
	{ value: "mn", text: "select.language.mn" },
	{ value: "na", text: "select.language.na" },
	{ value: "nv", text: "select.language.nv" },
	{ value: "nb", text: "select.language.nb" },
	{ value: "nd", text: "select.language.nd" },
	{ value: "ne", text: "select.language.ne" },
	{ value: "ng", text: "select.language.ng" },
	{ value: "nn", text: "select.language.nn" },
	{ value: "no", text: "select.language.no" },
	{ value: "ii", text: "select.language.ii" },
	{ value: "nr", text: "select.language.nr" },
	{ value: "oc", text: "select.language.oc" },
	{ value: "oj", text: "select.language.oj" },
	{ value: "cu", text: "select.language.cu" },
	{ value: "om", text: "select.language.om" },
	{ value: "or", text: "select.language.or" },
	{ value: "os", text: "select.language.os" },
	{ value: "pa", text: "select.language.pa" },
	{ value: "pi", text: "select.language.pi" },
	{ value: "fa", text: "select.language.fa" },
	{ value: "pl", text: "select.language.pl" },
	{ value: "ps", text: "select.language.ps" },
	{ value: "pt", text: "select.language.pt" },
	{ value: "qu", text: "select.language.qu" },
	{ value: "rm", text: "select.language.rm" },
	{ value: "rn", text: "select.language.rn" },
	{ value: "ro", text: "select.language.ro" },
	{ value: "ru", text: "select.language.ru" },
	{ value: "sa", text: "select.language.sa" },
	{ value: "sc", text: "select.language.sc" },
	{ value: "sd", text: "select.language.sd" },
	{ value: "se", text: "select.language.se" },
	{ value: "sm", text: "select.language.sm" },
	{ value: "sg", text: "select.language.sg" },
	{ value: "sr", text: "select.language.sr" },
	{ value: "gd", text: "select.language.gd" },
	{ value: "sn", text: "select.language.sn" },
	{ value: "si", text: "select.language.si" },
	{ value: "sk", text: "select.language.sk" },
	{ value: "sl", text: "select.language.sl" },
	{ value: "so", text: "select.language.so" },
	{ value: "st", text: "select.language.st" },
	{ value: "su", text: "select.language.su" },
	{ value: "sw", text: "select.language.sw" },
	{ value: "ss", text: "select.language.ss" },
	{ value: "sv", text: "select.language.sv" },
	{ value: "ta", text: "select.language.ta" },
	{ value: "te", text: "select.language.te" },
	{ value: "tg", text: "select.language.tg" },
	{ value: "th", text: "select.language.th" },
	{ value: "ti", text: "select.language.ti" },
	{ value: "bo", text: "select.language.bo" },
	{ value: "tk", text: "select.language.tk" },
	{ value: "tl", text: "select.language.tl" },
	{ value: "tn", text: "select.language.tn" },
	{ value: "to", text: "select.language.to" },
	{ value: "ts", text: "select.language.ts" },
	{ value: "tt", text: "select.language.tt" },
	{ value: "tw", text: "select.language.tw" },
	{ value: "ty", text: "select.language.ty" },
	{ value: "ug", text: "select.language.ug" },
	{ value: "uk", text: "select.language.uk" },
	{ value: "ur", text: "select.language.ur" },
	{ value: "uz", text: "select.language.uz" },
	{ value: "ve", text: "select.language.ve" },
	{ value: "vi", text: "select.language.vi" },
	{ value: "vo", text: "select.language.vo" },
	{ value: "wa", text: "select.language.wa" },
	{ value: "cy", text: "select.language.cy" },
	{ value: "wo", text: "select.language.wo" },
	{ value: "fy", text: "select.language.fy" },
	{ value: "xh", text: "select.language.xh" },
	{ value: "yi", text: "select.language.yi" },
	{ value: "yo", text: "select.language.yo" },
	{ value: "za", text: "select.language.za" }
]
