import React from "react"
import { size, filter } from "lodash"
import { Nav, ProgressBar, Col } from "react-bootstrap"
import wrapper from "../../../../../i18n/wrapper"

export default wrapper(({ values, t }) => {
	const identity = Number(values.identity)
	const style = "row align-items-center"
	const list =
		identity === 1 ? ["status", "operation", "signature"] : ["status"]

	const count = filter(values, (index, key) => index && list.indexOf(key) < 0)
	const percent = (size(count) * 100) / (identity === 1 ? 10 : 13)

	return (
		<Nav variant="pills" className="flex-column">
			<Nav.Item>
				<h4>{t("header.progress")}</h4>
				<ProgressBar animated now={percent} />
				<hr />
			</Nav.Item>
			<Nav.Item className={style}>
				<Col md={1} className="text-center">
					{values.identity ? (
						<i className="fas fa-check fa-lg text-primary"></i>
					) : (
						<i className="fas fa-times fa-lg"></i>
					)}
				</Col>
				<Col md={11}>{t("progress.team.identity")}</Col>
			</Nav.Item>
			<Nav.Item className={style}>
				<Col md={1} className="text-center">
					{values.title ? (
						<i className="fas fa-check fa-lg text-primary"></i>
					) : (
						<i className="fas fa-times fa-lg"></i>
					)}
				</Col>
				<Col md={11}>{t("progress.team.title")}</Col>
			</Nav.Item>
			<Nav.Item className={style}>
				<Col md={1} className="text-center">
					{values.website ? (
						<i className="fas fa-check fa-lg text-primary"></i>
					) : (
						<i className="fas fa-times fa-lg"></i>
					)}
				</Col>
				<Col md={11}>{t("progress.team.website")}</Col>
			</Nav.Item>
			<Nav.Item className={style}>
				<Col md={1} className="text-center">
					{values.website ? (
						<i className="fas fa-check fa-lg text-primary"></i>
					) : (
						<i className="fas fa-times fa-lg"></i>
					)}
				</Col>
				<Col md={11}>{t("progress.team.clarification")}</Col>
			</Nav.Item>
			<Nav.Item className={style + " " + (identity === 1 ? "d-none" : "")}>
				<Col md={1} className="text-center">
					{values.companyTitle ? (
						<i className="fas fa-check fa-lg text-primary"></i>
					) : (
						<i className="fas fa-times fa-lg"></i>
					)}
				</Col>
				<Col md={11}>{t("progress.team.companyTitle")}</Col>
			</Nav.Item>
			<Nav.Item className={style}>
				<Col md={1} className="text-center">
					{values.companyNumber ? (
						<i className="fas fa-check fa-lg text-primary"></i>
					) : (
						<i className="fas fa-times fa-lg"></i>
					)}
				</Col>
				<Col md={11}>{t("progress.team.companyNumber")}</Col>
			</Nav.Item>
			<Nav.Item className={style}>
				<Col md={1} className="text-center">
					{values.companyOffice ? (
						<i className="fas fa-check fa-lg text-primary"></i>
					) : (
						<i className="fas fa-times fa-lg"></i>
					)}
				</Col>
				<Col md={11}>{t("progress.team.companyOffice")}</Col>
			</Nav.Item>
			<Nav.Item className={style}>
				<Col md={1} className="text-center">
					{values.ownerTitle ? (
						<i className="fas fa-check fa-lg text-primary"></i>
					) : (
						<i className="fas fa-times fa-lg"></i>
					)}
				</Col>
				<Col md={11}>{t("progress.team.ownerTitle")}</Col>
			</Nav.Item>
			<Nav.Item className={style}>
				<Col md={1} className="text-center">
					{values.ownerEmail ? (
						<i className="fas fa-check fa-lg text-primary"></i>
					) : (
						<i className="fas fa-times fa-lg"></i>
					)}
				</Col>
				<Col md={11}>{t("progress.team.ownerEmail")}</Col>
			</Nav.Item>
			<Nav.Item className={style}>
				<Col md={1} className="text-center">
					{values.ownerPhone ? (
						<i className="fas fa-check fa-lg text-primary"></i>
					) : (
						<i className="fas fa-times fa-lg"></i>
					)}
				</Col>
				<Col md={11}>{t("progress.team.ownerPhone")}</Col>
			</Nav.Item>
			<Nav.Item className={style}>
				<Col md={1} className="text-center">
					{values.taxation ? (
						<i className="fas fa-check fa-lg text-primary"></i>
					) : (
						<i className="fas fa-times fa-lg"></i>
					)}
				</Col>
				<Col md={11}>{t("progress.team.taxation")}</Col>
			</Nav.Item>
			<Nav.Item className={style}>
				<Col md={1} className="text-center">
					{values.obligation ? (
						<i className="fas fa-check fa-lg text-primary"></i>
					) : (
						<i className="fas fa-times fa-lg"></i>
					)}
				</Col>
				<Col md={11}>{t("progress.team.obligation")}</Col>
			</Nav.Item>
			<Nav.Item className={style + " " + (identity === 1 ? "d-none" : "")}>
				<Col md={1} className="text-center">
					{values.operation ? (
						<i className="fas fa-check fa-lg text-primary"></i>
					) : (
						<i className="fas fa-times fa-lg"></i>
					)}
				</Col>
				<Col md={11}>{t("progress.team.operation")}</Col>
			</Nav.Item>
			<Nav.Item className={style + " " + (identity === 1 ? "d-none" : "")}>
				<Col md={1} className="text-center">
					{values.signature ? (
						<i className="fas fa-check fa-lg text-primary"></i>
					) : (
						<i className="fas fa-times fa-lg"></i>
					)}
				</Col>
				<Col md={11}>{t("progress.team.signature")}</Col>
			</Nav.Item>
			<Nav.Item className={style}>
				<Col md={1} className="text-center">
					{values.agreement ? (
						<i className="fas fa-check fa-lg text-primary"></i>
					) : (
						<i className="fas fa-times fa-lg"></i>
					)}
				</Col>
				<Col md={11}>{t("progress.team.agreement")}</Col>
			</Nav.Item>
		</Nav>
	)
})
