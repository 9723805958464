import React from "react"
import { Modal, Button } from "react-bootstrap"
import { confirmable, createConfirmation } from "react-confirm"

export default createConfirmation(
	confirmable(
		({
			show,
			proceed,
			dismiss,
			cancel,
			content,
			title,
			textCancel,
			textNew,
			textOkey
		}) => (
			<Modal
				size="small"
				show={show}
				onHide={() => proceed(false)}
				backdrop="static"
				centered
			>
				<Modal.Header>
					<Modal.Title>{title}</Modal.Title>
				</Modal.Header>
				<Modal.Body>{content}</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => proceed(0)}>
						{textCancel}
					</Button>
					<Button variant="primary" onClick={() => proceed(2)}>
						{textNew}
					</Button>
					<Button variant="primary" onClick={() => proceed(1)}>
						{textOkey}
					</Button>
				</Modal.Footer>
			</Modal>
		)
	)
)
