import React from "react"
import { size } from "lodash"

import { Table } from "react-bootstrap"
import wrapper from "../../../../../i18n/wrapper"
import Pagination from "../../../../layout/component/context/pagination"
import Date from "../../../../layout/component/element/date"
import Text from "../../../../layout/component/element/text"

export default wrapper(
	({ t, setArray, authentication, array, query, token }) => (
		<Table striped>
			<thead>
				<tr>
					<th>{t("input.title.contact")}</th>
					<th>{t("input.identity.contact")}</th>
					<th>{t("input.number.contact")}</th>
					<th>{t("input.office")}</th>
					<th>{t("input.title.auth")}</th>
					<th>{t("input.phone")}</th>
					<th>{t("input.email")}</th>
					<th>{t("input.created.contact")}</th>
				</tr>
			</thead>
			<tbody>
				{size(array) > 0 ? (
					array.map((index, key) => (
						<tr key={key}>
							<td>
								<Text index={index.title} />
							</td>
							<td>
								<Text index={"select.identity." + index.identity} />
							</td>
							<td>
								<Text index={index.number} />
							</td>
							<td>
								<Text index={index.office} />
							</td>
							<td>
								<Text index={index.auth} />
							</td>
							<td>
								<Text index={index.phone} />
							</td>
							<td>
								<Text index={index.email} />
							</td>
							<td>
								<Date index={index.created_at} />
							</td>
						</tr>
					))
				) : (
					<tr>
						<td colSpan="9" className="text-center">
							{t("inline.empty")}
						</td>
					</tr>
				)}
			</tbody>
			<tfoot className={!token ? "d-none" : ""}>
				<tr>
					<td colSpan="9" className="text-right">
						<Pagination
							setArray={setArray}
							authentication={authentication}
							query={query}
							token={token}
						/>
					</td>
				</tr>
			</tfoot>
		</Table>
	)
)
