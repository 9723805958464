import React, { useState } from "react"
import { Query } from "react-apollo"
import { get, size } from "lodash"
import gql from "graphql-tag"

import { Container, Spinner } from "react-bootstrap"
import Table from "./index/table"

const query = `
    query listModuleContact($authentication: String!, $nextToken: String) {
        list:listModuleContact(authentication: $authentication, nextToken: $nextToken) {
            items {
                title
                identity
                number
                office
                auth
                email
                phone
                created_at
                updated_at
            }
            nextToken
        }
    }
`

export default ({ authentication }) => {
	const [array, setArray] = useState([])

	return (
		<Query query={gql(query)} variables={{ authentication: authentication }}>
			{({ loading, data }) => {
				const items = get(data, "list.items")
				const token = get(data, "list.nextToken")
				const listing = size(array) > 0 ? array : items

				return loading ? (
					<Container className="px-0 text-center" fluid>
						<Spinner animation="border" size="sm" />
					</Container>
				) : (
					<Container className="px-0" fluid>
						<Table
							setArray={setArray}
							authentication={authentication}
							array={listing}
							query={query}
							token={token}
						/>
					</Container>
				)
			}}
		</Query>
	)
}
