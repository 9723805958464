import React from "react"
import { Container } from "react-bootstrap"
import wrapper from "../../../i18n/wrapper"

export default wrapper(({ t }) => (
	<div id="center">
		<Container className="compact">
			<div className="my-2 text-center">
				<h3>{t("header.error")}</h3>
				<p className="text-secondary">{t("content.error")}</p>
			</div>
		</Container>
	</div>
))
