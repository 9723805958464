import React from "react"
import { Link } from "react-router-dom"
import { Nav } from "react-bootstrap"
import wrapper from "../../../../../i18n/wrapper"

export default wrapper(({ t }) => (
	<Nav variant="pills" className="flex-column">
		<Nav.Item>
			<Link to="/panel/musteriler/olustur" className="nav-link active">
				<h4>{t("step.contact.basic.header")}</h4>
				<p>{t("step.contact.basic.content")}</p>
			</Link>
		</Nav.Item>
	</Nav>
))
