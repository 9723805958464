import React from "react"
import { Form, Modal, InputGroup, Button } from "react-bootstrap"
import { Link } from "react-router-dom"

import QRCode from "qrcode.react"
import copy from "copy-to-clipboard"
import wrapper from "../../../../i18n/wrapper"

const download = (node, query) => {
	if (node) {
		const canvas = document.getElementById("canvas")
		node.href = canvas.toDataURL()
		node.download = `${query}.png`
	}
}

export default wrapper(({ setShow, location, query, t }) => {
	return (
		<Form className="bg-light">
			<Modal.Body className="text-center">
				<QRCode id="canvas" value={location} size={124} />
				<div className="my-2 text-center">
					<p className="text-secondary">{t("content.query")}</p>
					<p className="text-secondary mb-0">
						<Link
							to="/download"
							target="_blank"
							innerRef={(node) => download(node, query)}
						>
							<Button variant="secondary">{t("button.download.query")}</Button>
						</Link>
					</p>
				</div>
			</Modal.Body>
			<hr />
			<Modal.Body>
				<Form.Group>
					<Form.Label>
						<b className="text-secondary">{t("input.query")}</b>
					</Form.Label>
					<InputGroup>
						<Form.Control type="text" value={query} disabled />
						<InputGroup.Append>
							<Button variant="secondary" onClick={() => copy(query)}>
								{t("button.copy")}
							</Button>
						</InputGroup.Append>
					</InputGroup>
				</Form.Group>
				<Form.Group>
					<Form.Label>
						<b className="text-secondary">{t("input.location.query")}</b>
					</Form.Label>
					<InputGroup>
						<Form.Control type="text" value={location} disabled />
						<InputGroup.Append>
							<Button variant="secondary" onClick={() => copy(location)}>
								{t("button.copy")}
							</Button>
						</InputGroup.Append>
					</InputGroup>
				</Form.Group>
			</Modal.Body>
		</Form>
	)
})
