import React from "react"
import { get, size } from "lodash"

import { Table } from "react-bootstrap"
import wrapper from "../../../../../i18n/wrapper"
import Pagination from "../../../../layout/component/context/pagination"
import Date from "../../../../layout/component/element/date"
import Status from "../../../../layout/component/element/status"
import Text from "../../../../layout/component/element/text"
import Agent from "../../../../layout/component/element/agent"

export default wrapper(
	({ t, setArray, authentication, array, query, token }) => (
		<Table striped>
			<thead>
				<tr>
					<th>{t("input.number.storage")}</th>
					<th>{t("input.verification")}</th>
					<th>{t("input.verify")}</th>
					<th>{t("input.input")}</th>
					<th>{t("input.ip")}</th>
					<th>{t("input.agent")}</th>
					<th>{t("input.created.history")}</th>
					<th>{t("input.updated")}</th>
					<th>{t("input.status")}</th>
				</tr>
			</thead>
			<tbody>
				{size(array) > 0 ? (
					array.map((index, key) => {
						const storage = get(index, "storage", {})
						const verify = index.secret ? index.secret : storage.verify

						const input = get(index, "input")
						const object = input ? JSON.parse(input) : {}
						const title =
							object.name && object.lastname
								? object.name + " " + object.lastname
								: object.name
								? object.name
								: object.lastname

						return (
							<tr key={key}>
								<td>
									<Text index={storage.number} />
								</td>
								<td>
									<Text index={"select.verification." + storage.verification} />
								</td>
								<td>
									<Text index={verify} />
								</td>
								<td>
									<Text index={title} />
								</td>
								<td>
									<Text index={index.ip} />
								</td>
								<td>
									<Agent index={index.agent} />
								</td>
								<td>
									<Date index={index.created_at} />
								</td>
								<td>
									<Date index={index.updated_at} />
								</td>
								<td>
									<Status index={index.status} />
								</td>
							</tr>
						)
					})
				) : (
					<tr>
						<td colSpan="9" className="text-center">
							{t("inline.empty")}
						</td>
					</tr>
				)}
			</tbody>
			<tfoot className={!token ? "d-none" : ""}>
				<tr>
					<td colSpan="9" className="text-right">
						<Pagination
							setArray={setArray}
							authentication={authentication}
							query={query}
							token={token}
						/>
					</td>
				</tr>
			</tfoot>
		</Table>
	)
)
