import i18n from "i18next"
import detector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"

import tr from "./locales/tr.json"
import en from "./locales/en.json"

const fallback = ["tr"]
const whitelist = ["en", "tr"]
const resources = {
	en: { translation: en },
	tr: { translation: tr }
}

const options = {
	order: ["querystring", "cookie", "navigator", "htmlTag"],
	lookupQuerystring: "language",
	lookupCookie: "language",
	caches: ["cookie"],
	cookieMinutes: 43200, // 60*24*30
	htmlTag: document.documentElement,
	cookieOptions: { path: "/" }
}

i18n
	.use(detector)
	.use(initReactI18next)
	.init({
		resources: resources,
		fallbackLng: fallback,
		whitelist: whitelist,
		detection: options,
		debug: false,
		interpolation: {
			escapeValue: false
		}
	})

export default i18n
