import React from "react"
import { connect } from "react-redux"
import { get, size } from "lodash"

import { Table } from "react-bootstrap"
import wrapper from "../../../../../i18n/wrapper"
import Pagination from "../../../../layout/component/context/pagination"
import Date from "../../../../layout/component/element/date"
import File from "../../../../layout/component/element/file"
import Select from "../../../../layout/component/element/select"
import Text from "../../../../layout/component/element/text"

export default connect((state) => ({
	state: state
}))(
	wrapper(({ state, t, setArray, authentication, array, query, token }) => {
		const language = get(state, "selection.language", [])

		return (
			<Table striped>
				<thead>
					<tr>
						<th>{t("input.number.storage")}</th>
						<th>{t("input.invoice")}</th>
						<th>{t("input.owner")}</th>
						<th>{t("input.category.storage")}</th>
						<th>{t("input.privacy")}</th>
						<th>{t("input.deadline")}</th>
						<th>{t("input.verification")}</th>
						<th>{t("input.verify")}</th>
						<th>{t("input.source")}</th>
						<th>{t("input.sourceLanguage")}</th>
						<th>{t("input.target")}</th>
						<th>{t("input.targetLanguage")}</th>
						<th>{t("input.created.storage")}</th>
					</tr>
				</thead>
				<tbody>
					{size(array) > 0 ? (
						array.map((index, key) => {
							const contact = get(index, "contact", {})
							const storage = index.SK.replace(/^moduleStorage#/, "")
							const link = "/dogrulama/" + storage

							return (
								<tr key={key}>
									<td>
										<Text index={index.number} />
									</td>
									<td>
										<Text index={index.invoice} />
									</td>
									<td>
										<Text index={contact.title} />
									</td>
									<td>
										<Text index={index.category} />
									</td>
									<td>
										<Text index={"select.privacy." + index.privacy} />
									</td>
									<td>
										<Text index={"select.deadline." + index.deadline} />
									</td>
									<td>
										<Text index={"select.verification." + index.verification} />
									</td>
									<td>
										<Text index={index.verify} />
									</td>
									<td>
										<File index={index.source} link={link} />
									</td>
									<td>
										<Select index={index.sourceLanguage} array={language} />
									</td>
									<td>
										<File index={index.target} link={link} />
									</td>
									<td>
										<Select index={index.targetLanguage} array={language} />
									</td>
									<td>
										<Date index={index.created_at} />
									</td>
								</tr>
							)
						})
					) : (
						<tr>
							<td colSpan="13" className="text-center">
								{t("inline.empty")}
							</td>
						</tr>
					)}
				</tbody>
				<tfoot className={!token ? "d-none" : ""}>
					<tr>
						<td colSpan="13" className="text-right">
							<Pagination
								setArray={setArray}
								authentication={authentication}
								query={query}
								token={token}
							/>
						</td>
					</tr>
				</tfoot>
			</Table>
		)
	})
)
