import React, { useState } from "react"
import { withRouter } from "react-router-dom"
import { Mutation } from "react-apollo"
import { get, size } from "lodash"
import gql from "graphql-tag"
import uuid from "react-uuid"

import { Formik } from "formik"
import { Container, Row, Col, Alert, Form, Modal } from "react-bootstrap"
import wrapper from "../../../../i18n/wrapper"
import Step from "./create/step"
import Basic from "./create/basic"

const query = `
    mutation createModuleContact($authentication: String!, $input: createInputModuleContact!){
        create:createModuleContact(authentication: $authentication, input: $input){
            SK
            title
        }
    }
`

const ComponentForm = wrapper(
	({ handleSubmit, handleChange, values, error, loading, t }) => (
		<Form onSubmit={handleSubmit} className="bg-light">
			{error ? (
				<Modal.Body className="pb-0 border-bottom">
					<Alert variant="danger">{t(error)}</Alert>
				</Modal.Body>
			) : null}
			<Basic handleChange={handleChange} values={values} loading={loading} />
		</Form>
	)
)

const ComponentBasic = wrapper(
	({ handleSubmit, handleChange, layout, values, error, loading, t }) =>
		layout === "modal" ? (
			<ComponentForm
				handleSubmit={handleSubmit}
				handleChange={handleChange}
				values={values}
				error={error}
				loading={loading}
			/>
		) : (
			<Container>
				<div className="pb-5 text-center">
					<h2>{t("header.contact")}</h2>
					<p className="lead">{t("content.contact")}</p>
				</div>
				<Row>
					<Col md={8}>
						<ComponentForm
							handleSubmit={handleSubmit}
							handleChange={handleChange}
							values={values}
							error={error}
							loading={loading}
						/>
					</Col>
					<Col md={4} className="d-none d-md-block">
						<Step />
					</Col>
				</Row>
			</Container>
		)
)

export default withRouter(
	({ setShow, setFieldValue, history, authentication, name }) => {
		const [error, setError] = useState(null)
		const [loading, setLoading] = useState(false)
		const layout = setShow && setFieldValue ? "modal" : "default"

		return (
			<Mutation mutation={gql(query)}>
				{(create) => (
					<Formik
						initialValues={{
							SK: uuid(),
							identity: 1,
							title: "",
							number: "",
							office: "",
							auth: "",
							email: "",
							phone: "90"
						}}
						onSubmit={({
							SK,
							title,
							identity,
							number,
							office,
							auth,
							email,
							phone
						}) => {
							setError(null)
							setLoading(true)

							create({
								variables: {
									authentication: authentication,
									input: {
										SK: SK,
										title: size(String(title)) > 0 ? title : null,
										identity: Number(identity),
										number: size(String(number)) > 0 ? Number(number) : null,
										office: size(String(office)) > 0 ? office : null,
										auth: size(String(auth)) > 0 ? auth : null,
										email: size(String(email)) > 0 ? email.trim() : null,
										phone: size(String(phone)) ? "+" + Number(phone) : null
									}
								}
							})
								.then((response) => {
									if (layout === "modal") {
										setLoading(false)
										const create = get(response, "data.create")

										const sk = get(create, "SK")
										const title = get(create, "title")

										if (sk && title) {
											setFieldValue(name, {
												value: sk.replace(/^moduleContact#/, ""),
												title
											})
											setShow(false)
										}
									} else {
										setLoading(false)
										history.push("/panel/musteriler")
									}
								})
								.catch((response) => {
									setLoading(false)
									const type = get(response, "graphQLErrors.0.errorType")
									const message = get(response, "graphQLErrors.0.message")

									if (type && message) {
										setError(message)
									} else {
										setError("error.graphql.default")
									}
								})
						}}
					>
						{({ handleSubmit, handleChange, setFieldValue, values }) => (
							<ComponentBasic
								handleSubmit={handleSubmit}
								handleChange={handleChange}
								layout={layout}
								values={values}
								error={error}
								loading={loading}
							/>
						)}
					</Formik>
				)}
			</Mutation>
		)
	}
)
