import React, { useState } from "react"
import { Link } from "react-router-dom"
import { Form, Col, InputGroup, Modal } from "react-bootstrap"

import ContactCreate from "../../contact/create"
import wrapper from "../../../../../i18n/wrapper"
import Search from "../../../../layout/component/input/search"

const contactQuery = `
    query listModuleContact($authentication: String!, $filter: filterInputModuleContact) {
        list:listModuleContact(authentication: $authentication, filter: $filter) {
            items {
                SK
                title
            }
        }
    }
`

const ContactComponent = wrapper(
	({ setFieldValue, authentication, name, t }) => {
		const [show, setShow] = useState(false)

		return (
			<>
				<b className="text-primary" role="button" onClick={() => setShow(true)}>
					<i className="fas fa-plus mr-1"></i>
					{t("link.new")}
				</b>
				<Modal show={show} onHide={() => setShow(false)} size="lg">
					<Modal.Header closeButton>
						<Modal.Title>{t("header.contact")}</Modal.Title>
					</Modal.Header>
					<ContactCreate
						setShow={setShow}
						setFieldValue={setFieldValue}
						authentication={authentication}
						name={name}
					/>
				</Modal>
			</>
		)
	}
)

export default wrapper(
	({ handleChange, setFieldValue, authentication, values, t }) => {
		return (
			<>
				<Modal.Body>
					<Form.Row>
						<Col>
							<Form.Group>
								<Form.Label>
									<b className="text-secondary">{t("input.number.storage")}</b>
								</Form.Label>
								<InputGroup>
									<InputGroup.Prepend>
										<InputGroup.Text>
											<i className="fas fa-hashtag"></i>
										</InputGroup.Text>
									</InputGroup.Prepend>
									<Form.Control
										name="number"
										type="text"
										autoComplete="off"
										value={values.number}
										onChange={handleChange}
									/>
								</InputGroup>
							</Form.Group>
						</Col>
						<Col>
							<Form.Group>
								<Form.Label>
									<b className="text-secondary">{t("input.invoice")}</b>
								</Form.Label>
								<InputGroup>
									<InputGroup.Prepend>
										<InputGroup.Text>
											<i className="fas fa-file-invoice"></i>
										</InputGroup.Text>
									</InputGroup.Prepend>
									<Form.Control
										name="invoice"
										type="text"
										autoComplete="off"
										value={values.invoice}
										onChange={handleChange}
									/>
								</InputGroup>
							</Form.Group>
						</Col>
					</Form.Row>
					<Form.Group>
						<Form.Label>
							<b className="text-secondary">{t("input.owner")}</b>
						</Form.Label>
						<Form.Label className="float-right">
							<ContactComponent
								setFieldValue={setFieldValue}
								authentication={authentication}
								name="GSI2P"
							/>
						</Form.Label>
						<Search
							name="GSI2P"
							placeholder={t("placeholder.owner")}
							query={contactQuery}
							value={values.GSI2P}
							authentication={authentication}
							setFieldValue={setFieldValue}
						/>
					</Form.Group>
					<Form.Group>
						<Form.Label>
							<b className="text-secondary">{t("input.category.storage")}</b>
						</Form.Label>
						<Form.Control
							name="category"
							type="text"
							autoComplete="off"
							placeholder={t("placeholder.category")}
							value={values.category}
							onChange={handleChange}
						/>
					</Form.Group>
					<Form.Group>
						<Form.Label>
							<b className="text-secondary">{t("input.privacy")}</b>
						</Form.Label>
						<Form.Control
							as="select"
							name="privacy"
							value={values.privacy}
							onChange={handleChange}
							custom
						>
							<option value={1}>{t("select.privacy.1")}</option>
							<option value={2}>{t("select.privacy.2")}</option>
							<option value={3}>{t("select.privacy.3")}</option>
							<option value={4}>{t("select.privacy.4")}</option>
						</Form.Control>
					</Form.Group>
					<Form.Group>
						<Form.Label>
							<b className="text-secondary">{t("input.deadline")}</b>
						</Form.Label>
						<Form.Control
							as="select"
							name="deadline"
							value={values.deadline}
							onChange={handleChange}
							custom
						>
							<option value={180}>{t("select.deadline.180")}</option>
							<option value={365}>{t("select.deadline.365")}</option>
							<option value={730}>{t("select.deadline.730")}</option>
							<option value={1825}>{t("select.deadline.1825")}</option>
						</Form.Control>
					</Form.Group>
				</Modal.Body>
				<Modal.Footer>
					<Form.Group className="text-right">
						<Link
							to="/panel/onaylar/olustur/onayla"
							className="btn btn-primary"
						>
							{t("button.next")}
						</Link>
					</Form.Group>
				</Modal.Footer>
			</>
		)
	}
)
