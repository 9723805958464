import React, { useState } from "react"
import { withRouter } from "react-router-dom"
import { Mutation } from "react-apollo"
import { get, size } from "lodash"
import gql from "graphql-tag"

import { Formik } from "formik"
import { Container, Row, Col, Alert, Form, Modal } from "react-bootstrap"

import confirm from "../../../../function/confirm"
import wrapper from "../../../../i18n/wrapper"
import Step from "./create/step"
import Basic from "./create/basic"
import Verify from "./create/verify"

const query = `
    mutation createModuleStorage($authentication: String!, $input: createInputModuleStorage!){
        create:createModuleStorage(authentication: $authentication, input: $input){
            created_at
            updated_at
        }
    }
`

const ComponentForm = wrapper(
	({
		handleSubmit,
		handleChange,
		setFieldValue,
		authentication,
		values,
		error,
		loading,
		step,
		t
	}) => (
		<Form onSubmit={handleSubmit} className="bg-light">
			{error ? (
				<Modal.Body className="pb-0 border-bottom">
					<Alert variant="danger">{t(error)}</Alert>
				</Modal.Body>
			) : null}
			{step === "onayla" ? (
				<Verify
					handleChange={handleChange}
					setFieldValue={setFieldValue}
					values={values}
					loading={loading}
				/>
			) : (
				<Basic
					handleChange={handleChange}
					setFieldValue={setFieldValue}
					authentication={authentication}
					values={values}
				/>
			)}
		</Form>
	)
)

const ComponentBasic = wrapper(
	({
		handleSubmit,
		handleChange,
		setFieldValue,
		authentication,
		values,
		error,
		loading,
		step,
		t
	}) => (
		<Container>
			<div className="pb-5 text-center">
				<h2>{t("header.storage")}</h2>
				<p className="lead">{t("content.storage")}</p>
			</div>
			<Row>
				<Col md={8}>
					<ComponentForm
						handleSubmit={handleSubmit}
						handleChange={handleChange}
						setFieldValue={setFieldValue}
						authentication={authentication}
						values={values}
						error={error}
						loading={loading}
						step={step}
					/>
				</Col>
				<Col md={4} className="d-none d-md-block">
					<Step step={step} />
				</Col>
			</Row>
		</Container>
	)
)

export default withRouter(
	wrapper(({ history, authentication, step, t }) => {
		const [error, setError] = useState(null)
		const [loading, setLoading] = useState(false)

		return (
			<Mutation mutation={gql(query)}>
				{(create) => (
					<Formik
						initialValues={{
							SK: "",
							number: "",
							invoice: "",
							GSI2P: {},
							category: "",
							privacy: 1,
							deadline: 180,
							text: "",
							verification: 1,
							verify: Math.round(Math.random() * 100000000),
							source: null,
							sourceLanguage: "",
							sourceTimestamp: null,
							target: null,
							targetLanguage: "",
							targetTimestamp: null
						}}
						onSubmit={async (
							{
								SK,
								number,
								invoice,
								GSI2P,
								category,
								privacy,
								deadline,
								text,
								verification,
								verify,
								source,
								sourceLanguage,
								sourceTimestamp,
								target,
								targetLanguage,
								targetTimestamp
							},
							{ setFieldValue }
						) => {
							const status = await confirm({
								title: t("header.confirm.storage"),
								content: t("content.confirm.storage"),
								textCancel: t("button.cancel"),
								textNew: t("button.confirm_new"),
								textOkey: t("button.confirm")
							})

							if (status === 1 || status === 2) {
								setError(null)
								setLoading(true)

								create({
									variables: {
										authentication: authentication,
										input: {
											SK: SK,
											number: size(String(number)) > 0 ? number : null,
											invoice: size(String(invoice)) > 0 ? invoice : null,
											GSI2P: size(GSI2P) > 0 ? GSI2P.value : null,
											category: size(String(category)) > 0 ? category : null,
											privacy: Number(privacy),
											deadline: Number(deadline),
											text: size(String(text)) > 0 ? text : null,
											verification: Number(verification),
											verify: size(String(verify)) > 0 ? verify : null,
											sourceLanguage:
												size(String(sourceLanguage)) > 0
													? sourceLanguage
													: null,
											targetLanguage:
												size(String(targetLanguage)) > 0
													? targetLanguage
													: null,
											source:
												source instanceof Object
													? JSON.stringify(source)
													: null,
											target:
												target instanceof Object
													? JSON.stringify(target)
													: null,
											sourceTimestamp:
												sourceTimestamp instanceof Object
													? JSON.stringify(sourceTimestamp)
													: null,
											targetTimestamp:
												targetTimestamp instanceof Object
													? JSON.stringify(targetTimestamp)
													: null
										}
									}
								})
									.then((response) => {
										setLoading(false)
										if (status === 1) {
											history.push("/panel/onaylar")
										} else {
											setFieldValue("SK", "")
											setFieldValue("source", "")
											setFieldValue("target", "")
											history.push("/panel/onaylar/olustur/temel")
										}
									})
									.catch((response) => {
										setLoading(false)
										const type = get(response, "graphQLErrors.0.errorType")
										const message = get(response, "graphQLErrors.0.message")

										if (type && message) {
											setError(message)
										} else {
											setError("error.graphql.default")
										}
									})
							}
						}}
					>
						{({ handleSubmit, handleChange, setFieldValue, values }) => (
							<ComponentBasic
								handleSubmit={handleSubmit}
								handleChange={handleChange}
								setFieldValue={setFieldValue}
								authentication={authentication}
								values={values}
								error={error}
								loading={loading}
								step={step}
							/>
						)}
					</Formik>
				)}
			</Mutation>
		)
	})
)
