import React from "react"
import { withRouter } from "react-router-dom"
import { Query } from "react-apollo"
import { get } from "lodash"
import gql from "graphql-tag"

import { Container, Spinner } from "react-bootstrap"
import Sms from "./download/sms"
import Code from "./download/code"
import Identity from "./download/identity"
import Empty from "./download/empty"

export const query = `
	query getModuleStorageOuter($storage: String!) {
		get:getModuleStorageOuter(storage: $storage) {
			category
		    privacy
		    sourceLanguage
		    targetLanguage
		    verification
		    created_at
			contact {
				title
    		}
			team {
				title
				image
				website
				clarification
    		}
		}
	}
`

export default withRouter(({ match }) => {
	const storage = get(match, "params.storage")

	return (
		<Query query={gql(query)} variables={{ storage: storage }}>
			{({ loading, data }) => {
				const content = get(data, "get")
				const number = get(content, "verification")
				const index = Number(number)

				return loading ? (
					<div id="center">
						<Container className="compact text-center">
							<Spinner animation="border" size="sm" />
						</Container>
					</div>
				) : index === 3 ? (
					<Identity content={content} storage={storage} />
				) : index === 2 ? (
					<Sms content={content} storage={storage} />
				) : index === 1 ? (
					<Code content={content} storage={storage} />
				) : (
					<Empty />
				)
			}}
		</Query>
	)
})
