import React, { Component } from "react"
import moment from "moment"
import { I18nextProvider } from "react-i18next"
import { withTranslation } from "react-i18next"
import { default as i18next } from "./config"

import "moment/locale/tr"

export default (Wrapped) => {
	Wrapped = withTranslation()(Wrapped)
	moment.locale(i18next.language)

	return class extends Component {
		render() {
			return (
				<I18nextProvider i18n={i18next}>
					<Wrapped {...this.props} language={i18next.language} />
				</I18nextProvider>
			)
		}
	}
}
